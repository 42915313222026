import React, { useState } from "react";
import { useFieldStoreContext } from "../../stores/useStoreContext";
import { Badge, ActionIcon, Group, Space, Button, ScrollArea, Tabs } from "@mantine/core";
import { Users, Briefcase, BuildingBank, Search, Trash, Plus } from 'tabler-icons-react';
import { IconPhoto, IconMessageCircle, IconSettings, IconAssembly } from '@tabler/icons-react';
import Header from "../../components/header";
import FieldItem from "./fieldItem";
import FieldModal from "./fieldModal";
const Field = () => {
    const { candidate_extra_field, editCandidateExtraFieldByID, addCandidateExtraField, removeCandidateExtraFieldByID } = useFieldStoreContext()
    const { job_extra_field, getJobExtraField, addJobExtraField, editJobExtraFieldByID, removeJobExtraFieldByID } = useFieldStoreContext()
    const { client_extra_field, getClientExtraField, addClientExtraField, editClientExtraFieldByID, removeClientExtraFieldByID } = useFieldStoreContext()
    const { candidateAssessment_extra_field, addCandidateAssessmentExtraField, getCandidateAssessmentExtraField, editCandidateAssessmentExtraFieldByID, removeCandidateAssessmentExtraFieldByID } = useFieldStoreContext()
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState({ label: '' })
    const [type, setType] = useState('Candidate')
    const [isAdd, setisAdd] = useState(false)
    const closeModal = () => {
        setShowModal(false)
        setModalData({ label: '' })
    }
    const addField = () => {
        setShowModal(true)
        setModalData({ label: '' })
        setisAdd(true)
    }
    const editField = (data) => {
        setShowModal(true)
        setModalData(data)
        setisAdd(false)
    }
    const fieldModalJson = {
        type,
        showModal,
        closeModal,
        modalData,
        isAdd,
        editFun: type === 'Candidate' ? editCandidateExtraFieldByID : type === 'Job' ? editJobExtraFieldByID : type === 'Client' ? editClientExtraFieldByID : editCandidateAssessmentExtraFieldByID,
        addFun: type === 'Candidate' ? addCandidateExtraField : type === 'Job' ? addJobExtraField : type === 'Client' ? addClientExtraField : addCandidateAssessmentExtraField,
    }
    return (
        <ScrollArea h={"calc(100vh - 80px)"}>
            <FieldModal {...fieldModalJson} />
            <Header>
                Field
            </Header>
            <div style={{ padding: '0 0.5rem' }}>
                <Tabs onTabChange={setType} value={type}>
                    <Tabs.List>
                        <Tabs.Tab value="Candidate" icon={<Users size={18} />}>Candidate</Tabs.Tab>
                        <Tabs.Tab value="Job" icon={<Briefcase size={18} />}>Job</Tabs.Tab>
                        <Tabs.Tab value="Client" icon={<BuildingBank size={18} />}>Client</Tabs.Tab>
                        <Tabs.Tab value="CandidateAssessment" icon={<IconAssembly size={18} />}>Candidate Assessment</Tabs.Tab>
                    </Tabs.List>

                    <Tabs.Panel value="Candidate" pt="xs">
                        <Button
                            size="xs"
                            style={{ marginBottom: 10 }}
                            onClick={() => addField()}
                            leftIcon={<Plus />}
                        >
                            Add
                        </Button>
                        <FieldItem data={candidate_extra_field} editField={editField} deleteFun={removeCandidateExtraFieldByID} />
                    </Tabs.Panel>

                    <Tabs.Panel value="Job" pt="xs">
                        <Button
                            size="xs"
                            style={{ marginBottom: 10 }}
                            onClick={() => addField()}
                            leftIcon={<Plus />}
                        >
                            Add
                        </Button>
                        <FieldItem data={job_extra_field} editField={editField} deleteFun={removeJobExtraFieldByID} />
                    </Tabs.Panel>

                    <Tabs.Panel value="Client" pt="xs">
                        <Button
                            size="xs"
                            style={{ marginBottom: 10 }}
                            onClick={() => addField()}
                            leftIcon={<Plus />}
                        >
                            Add
                        </Button>
                        <FieldItem data={client_extra_field} editField={editField} deleteFun={removeClientExtraFieldByID} />
                    </Tabs.Panel>

                    <Tabs.Panel value="CandidateAssessment" pt="xs">
                        <Button
                            size="xs"
                            style={{ marginBottom: 10 }}
                            onClick={() => addField()}
                            leftIcon={<Plus />}
                        >
                            Add
                        </Button>
                        <FieldItem data={candidateAssessment_extra_field} editField={editField} deleteFun={removeCandidateAssessmentExtraFieldByID} />
                    </Tabs.Panel>
                </Tabs>
            </div>

        </ScrollArea>
    )
}
export default Field