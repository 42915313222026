import { Link, useMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import CandidateJobsListView from "./candidateJobsListView";
import api from '../../api';
import { useNavigate, createSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
import { ActionIcon, Button, Menu, Box, Group } from "@mantine/core";
import { Edit, Search, Trash, Dots, Eye } from 'tabler-icons-react';


const CandidateJobsList = (props) => {

  const navigate = useNavigate();

	// // on load get the candidate details
	// useEffect(() => {
	// 	api().get(`candidates/${props.uid}/jobs`).then((response) => {
	// 		console.log(response.data);
	// 	});
	// }, []);

  const Actions = ({ id, f }) => {
    return (
      <Group spacing={4} position="right" noWrap>
        <ActionIcon
          name="summary"
          color="green"
          onClick={() => navigate({
            pathname: `/job/${props.uid}`,
            search: createSearchParams({
              tab: 'summary'
            }).toString()
          })}>
          <Eye size={16} />
        </ActionIcon>
      </Group>
    );

  };



  const candidateJobsSetting = {
    resources: `candidates/${props.uid}/jobs`,
    schema: [
      { name: 'PositionName', label: 'Position Name', sort: true, filter: true, display: (o, f) => <Link target="_blank" className={"textButton"} to={`/job/${o.ID}?tab=summary`}>{o.PositionName}</Link> },
      { name: 'CandidateJobStatus[0]Status', label: 'Match Status', sort: true, filter: true, display: (o, f) => <Link target="_blank" className={"textButton"} to={`/job/${o.ID}?tab=candidates`}>{o.CandidateJobStatus[0].Status}</Link> },
			{ name: 'CandidateCreatedAt', label: 'Job Created', sort: true, filter: true, display: (o, f) => dayjs(o.CreatedAt).format('DD/MM/YYYY') },
			{ name: 'CandidateLastUpdated', label: 'Job Last Updated', sort: true, filter: true, display: (o, f) => dayjs(o.UpdatedAt).format('DD/MM/YYYY') },
			{ name: 'JobMatchedAt', label: 'Matched', sort: true, filter: true, display: (o, f) => dayjs(o.CandidateJobStatus[0].CreatedAt).format('DD/MM/YYYY') },
			{ name: 'JobMatchedUpdatedAt', label: 'Match Last Updated', sort: true, filter: true, display: (o, f) => dayjs(o.CandidateJobStatus[0].CreatedAt).format('DD/MM/YYYY') },
    ],
    action: [
    ],
  };

  if (props.uid === undefined) {
    return <></>;
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: 'white',
          borderRadius: theme.radius.md,
          padding: theme.spacing.sm,
          paddingBottom: 0,
        })}
      >
        <CandidateJobsListView {...candidateJobsSetting} />
      </Box>
    </>
  );
};

export default CandidateJobsList;