import { Container, Title, Menu, Input, Flex, Box, Text, Button, Popover, MultiSelect, UnstyledButton, Group, ActionIcon, Center, Pagination, Space, Modal, TextInput, SimpleGrid } from '@mantine/core';
import { useState, useEffect } from 'react';
import {
  MapPin,
  Briefcase,
  Users,
  Language,
  Star,
  Backpack,
  School,
  Flag,
  BuildingFactory2,
  Eye,
  Edit
} from 'tabler-icons-react';
import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import useCURDStore from '../hooks/useCURDStore';
import dayjs from 'dayjs';
import { useNavigate, createSearchParams } from "react-router-dom";
import { isNotEmpty, useForm } from '@mantine/form';

const CandidateAdvancedSearchSaved = () => {
  const navigate = useNavigate();
  const {
    page,
    totalPage,
    listResources,
    resources,
  } = useCURDStore({ resourcesName: 'candidate/search/saved' });

  const [editSearchOpen, setEditSearchOpen] = useState(false);
  const [searchId, setSearchId] = useState(null);

  const form = useForm({
    initialValues: {
      search_name: '',
      search_description: '',
    },
    validate: {
      search_name: isNotEmpty('Search Name required'),
      search_description: isNotEmpty('Search Description required')
    }
  });

  const Actions = ({ id, f, o }) => {
    Object.keys(o).forEach((key) => (o[key] == null) && delete o[key]);
    delete o.id;
    delete o.created_at;
    delete o.updated_at;
    return (
      <Group spacing={4} position="right" noWrap>
        <Button size="xs" color="yellow" variant="outline" radius="md" leftIcon={<Edit />}
          onClick={() => {
            setEditSearchOpen(!editSearchOpen);
            setSearchId(id);
          }}>
          Edit
        </Button>
        <Button size="xs" color="green" variant="outline" radius="md" leftIcon={<Eye />}
        onClick={() => {
          navigate({
            pathname: `/candidate/advanced-search`,
            search: `?${createSearchParams(o)}`,
          });
        }}>
          View
        </Button>
      </Group>
    );

  };


  const schema = [
    { name: 'search_name', label: 'Search Name', sort: true, filter: true },
    {
      name: 'search_description', label: 'Search Description', sort: true, filter: true, display: (o, f) => <Text
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: 100,
        }}>{o.search_description}</Text>
    },
    { name: 'candidate_location', label: 'Location', sort: true, filter: true },
    { name: 'current_position', label: 'Experience', sort: true, filter: true },
    { name: 'current_company', label: 'Employer', sort: true, filter: true },
    { name: 'languages', label: 'Languages', sort: true, filter: true },
    { name: 'degree', label: 'Degree', sort: true, filter: true },
    { name: 'university', label: 'Universities', sort: true, filter: true },
    { name: 'nationality_custom', label: 'Nationalities', sort: true, filter: true },
    { name: 'industry', label: 'Industries', sort: true, filter: true },
    { name: 'actions', label: '', textAlignment: 'right', display: (o, f) => <Actions id={o.id} f={f} o={o} /> },
  ];

  return (
    <Container sx={(theme) => ({
      marginTop: theme.spacing.md,
      maxWidth: '100%'
    })}>
      <Title size={24} sx={(theme) => ({
        color: theme.colors.black[0],
        paddingBottom: theme.spacing.xs,
        '@media (max-width: 768px)': {
          fontSize: '1.5rem',
          paddingTop: theme.spacing.xs,
          alignSelf: 'center',
        }
      })}>
        Candidate Saved Search
      </Title>
      <Box>
        {
          resources.length > 0 && (
            <>
              <DataTable
                highlightOnHover
                striped
                columns={schema?.map((resource, index) => {
                  return {
                    key: index,
                    title: <Text mr={resource.name == 'actions' ? "xs" : "none"}>{resource.label}</Text>,
                    accessor: resource.name,
                    textAlignment: resource.textAlignment || 'left',
                    sortable: false,
                    render: (row) => {
                      return resource.display && resource.display(row) || row[resource.name];
                    }
                  };
                })}
                records={resources}
              />
              <Space h="lg" />
              <Center>
                <Pagination total={totalPage} page={page} onChange={(page) => listResources({ _page: page })} radius="md" />
              </Center>
            </>
          )
        }
      </Box>

      <Modal
        opened={editSearchOpen}
        onClose={() => {
          setEditSearchOpen(false);
        }}
        title={"Edit Search"}
        centered
        size="lg"
      >
        <form form={form} onSubmit={form.onSubmit((values) => {
        })}
        >
          <SimpleGrid cols={2}>
            <TextInput
              withAsterisk
              label="Search Name"
              placeholder="Search Name..."
              {...form.getInputProps('search_name')}
            />
            <TextInput
              withAsterisk
              label="Search Description"
              placeholder="Search Description"
              {...form.getInputProps('search_description')}
            />
            <TextInput
              withAsterisk
              label="Location"
              placeholder="Location"
              icon={<MapPin size={20} />}  {...form.getInputProps('candidate_location')}
            />
            <TextInput
              withAsterisk
              label="Experience"
              placeholder="Experience"
              icon={<Briefcase size={20} />}
              {...form.getInputProps('current_position')}
            />
            <TextInput
              withAsterisk
              label="Employer"
              placeholder="Employer"
              icon={<Users size={20} />}
              {...form.getInputProps('current_company')}
            />
            <TextInput
              withAsterisk
              label="Languages"
              placeholder="Languages"
              icon={<Language size={20} />}
              {...form.getInputProps('languages')}
            />
            <TextInput
              withAsterisk
              label="Degree"
              placeholder="Degree"
              icon={<Backpack size={20} />}
              {...form.getInputProps('degree')}
            />
            <TextInput
              withAsterisk
              label="University"
              placeholder="University"
              icon={<School size={20} />}
              {...form.getInputProps('university')}
            />
            <TextInput
              withAsterisk
              label="Nationalities Description"
              placeholder="Nationalities"
              icon={<Flag size={20} />}
              {...form.getInputProps('nationality_custom')}
            />
            <TextInput
              withAsterisk
              label="Industry"
              placeholder="Industries"
              icon={<BuildingFactory2 size={20} />}
              {...form.getInputProps('industry')}
            />
          </SimpleGrid>
          <Flex gap='xs' justify='right'>
            <Button
              sx={(theme) => ({
                marginTop: theme.spacing.md,
                textAlign: 'center',
                width: 100,
                '&:hover': {
                  backgroundColor: theme.colors.black[6],
                  color: theme.colors.white[0],
                }
              })}
              variant="subtle"
              onClick={() => {
                setEditSearchOpen(false);
                form.reset();
              }
              }
            >
              Cancel
            </Button>
            <Button
              sx={(theme) => ({
                marginTop: theme.spacing.md,
                textAlign: 'center',
                width: 100,
              })}
              type="submit"
            >
              Save
            </Button>
          </Flex>
        </form>

      </Modal>

    </Container>
  );
};

export default CandidateAdvancedSearchSaved;