import { Checkbox, Badge, Switch, Input, Table, NumberInput, Button, ActionIcon, Group } from '@mantine/core';
import { useState } from 'react';
import { jobAssignmentData } from '../../../mock/jobAssignmentTable';
import { Plus, Edit, Reload, Filter, Eye, Check, Trash, UserSearch } from 'tabler-icons-react';
import { IconX, IconDeviceFloppy } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import api from "../../../api";
import JobAddAssignModal from './jobAddAssignModal'
import { useMatch } from 'react-router-dom';
import { useJobStoreContext } from '../../../stores/useStoreContext';


function JobAssignmentTable(props) {
	const { params: { id: jobId } } = useMatch('/job/:id');
	const { job, getJobByID, setJobByID } = useJobStoreContext();
	const { JobAssignment } = props.job
	const [data, setData] = useState(JobAssignment === '' ? [] : JSON.parse(JobAssignment));
	const [showModal, setShowModal] = useState(false)
	const [checkList, setCheckList] = useState([])
	const deleteJobAssign = async (ID) => {
		const newData = JSON.parse(JSON.stringify(data))
		const newJobAssignment = newData.filter((item)=>item.id!==ID)
		try {
			const newList = JSON.stringify(newJobAssignment)
			const newJob = props.job
			await setJobByID(jobId, {
				data: { JobAssignment: newList },
			})
			notifications.show({
				icon: <Check />,
				message: 'successfully',
				color: "teal"
			})
		} catch (error) {
			notifications.show({
				icon: <IconX />,
				message: 'error',
				color: "red"
			})
		}

	}
	const saveCommission = async () => {
		const newData = JSON.parse(JSON.stringify(data))
		newData.forEach((item)=>{
			if(item.hasOwnProperty('newCommission')){
				item.commission=item.newCommission
				delete item.newCommission
			}
		})
		try {
			const newList = JSON.stringify(newData)
			await setJobByID(jobId, {
				data: { JobAssignment: newList },
			});
			notifications.show({
				icon: <Check />,
				message: 'Update successful',
				color: "teal"
			})
		} catch (error) {
			notifications.show({
				icon: <IconX />,
				message: 'Error',
				color: "red"
			})
		}
	}
	const commissionChange = (value, element) => {
		const newData = data
		newData.forEach(item => {
			if (item.id === element.id) {
				item.newCommission = value
			}
		});
		setData(JSON.parse(JSON.stringify(newData)))
	}
	const cancel = (element) => {
		const newData = JSON.parse(JSON.stringify(data))
		newData.forEach(item => {
			if (item.userId === element.userId) {
				delete item.newCommission
			}
		});
		setData(newData)
	}
	const switchChange = async(e, element) => {
		const newData = JSON.parse(JSON.stringify(data))
		newData.forEach(item => {
			if (item.id === element.id) {
				item.visible = e.currentTarget.checked
			}
		});
		try {
			const newList = JSON.stringify(newData)
			await setJobByID(jobId, {
				data: { JobAssignment: newList },
			});
			notifications.show({
				icon: <Check />,
				message: 'Update successful',
				color: "teal"
			})
		} catch (error) {
			notifications.show({
				icon: <IconX />,
				message: 'Error',
				color: "red"
			})
		}
	}
	const rows = data.map((element) => (
		<tr key={element.id}>
			<td>
				<Switch checked={element.visible} onChange={(e) => switchChange(e, element)} />
			</td>
			<td>{element.name}</td>
			<td>{element.email}</td>
			<td>{element.roles.map(r => <Badge radius="xs" variant="outline">{r}</Badge>)}</td>
			<td>
				<NumberInput
					defaultValue={element.commission}
					value={element.newCommission ? element.newCommission : element.commission == null ? '' : element.commission}
					placeholder="Commission"
					onChange={(value) => commissionChange(value, element)}
				/>
			</td>
			<td width={113}>
				<Group spacing={4} position="right" noWrap>
					{
						element.hasOwnProperty('newCommission') ? (
							<>
								<ActionIcon
									name="summary"
									color="green"
									onClick={() => saveCommission()}>
									<IconDeviceFloppy size={16} />
								</ActionIcon>
								<ActionIcon
									name="canel"
									color="red"
									onClick={() => cancel(element)}>
									<IconX size={16} />
								</ActionIcon>
							</>
						) : null
					}
					<ActionIcon
						name="delete"
						color="red"
						onClick={() => deleteJobAssign(element.id)}
					>
						<Trash size={16} />
					</ActionIcon>
				</Group>
			</td>
		</tr>
	));
	const showAssignModal = () => {
		setShowModal(true)
	}
	const closeASSignModal = () => {
		setShowModal(false)
		setCheckList([])
	}
	const checkBoxChange = (e, o) => {
		if (e.currentTarget.checked) {
			setCheckList([...checkList, o])
		} else {
			let _index = 0
			checkList.forEach((item, index) => {
				if (item.id === o.id) {
					_index = index
				}
			})
			const newCheckList = checkList
			newCheckList.splice(_index, 1)
			setCheckList(JSON.parse(JSON.stringify(newCheckList)))
		}
	}
	const addJob = async () => {
		if (checkList.length === 0) return
		const oldList = JobAssignment === '' ? [] : JSON.parse(JobAssignment)
		const realCheckList = []
		checkList.forEach((item) => {
			if (oldList.filter((jtem) => jtem.id === item.id).length === 0) {
				realCheckList.push(item)
			}
		})
		const list = []
		realCheckList.forEach((item) => {
			const newItem = {
				id: item.id,
				name: item.name,
				email: item.email,
				roles: item.roles,
				commission: null,
				visible: true,
			}
			list.push(newItem)
		})
		try {
			const newList = JSON.stringify([...oldList,...list])
			await setJobByID(jobId, {
				data: { JobAssignment: newList },
			});
			notifications.show({
				icon: <Check />,
				message: 'Update successful',
				color: "teal"
			})
		} catch (error) {
			notifications.show({
				icon: <IconX />,
				message: 'Error',
				color: "red"
			})
		}
		setShowModal(false)
		setCheckList([])
	}
	const userSetting = {
		resources: `users`,
		schema: [
			{
				name: '', label: '', textAlignment: 'left',
				display: (o) =>
					<Checkbox checked={checkList.filter((item) => item.id === o.id).length} onChange={(e) => checkBoxChange(e, o)} />
			},
			{ name: 'name', label: 'name', sort: true, filter: true },
			{
				name: 'email', label: 'email', sort: true, filter: true, display: (o) => <div>{
					o.email
				} {!!o.isNew ? <Badge color="red">PENDING</Badge> : <></>}</div>
			},
			{ name: 'roles', label: 'roles', sort: true, filter: false, display: (o) => <div>{o.roles.map(r => <Badge radius="xs" variant="outline">{r}</Badge>)}</div> },
			{ name: 'tag', label: 'tag', sort: true, filter: true },
		],
		action: [
		],
		createAllowed: true,
	};
	return (
		<div style={{ minWidth: 700 }}>
			<JobAddAssignModal {...userSetting} showModal={showModal} closeASSignModal={closeASSignModal} addJob={addJob} />
			<Button
				size="xs"
				style={{ marginBottom: 10 }}
				onClick={showAssignModal}
				leftIcon={<Plus />}
			>
				Add
			</Button>
			<Input
				icon={<UserSearch />}
				placeholder="Search"
			/>

			<Table>
				<thead>
					<tr>
						<th>Visible</th>
						<th>Name</th>
						<th>Email</th>
						<th>Role</th>
						<th>Commission in %</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
		</div>
	);
}

export default JobAssignmentTable;