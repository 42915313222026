import React, { useEffect } from "react";
import { Loader, Center } from '@mantine/core';
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import { setGlobalStateWithLocalStorage } from '../stores/store';
import axios from "axios";

const AutoLogin = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const user = searchParams.get('u')
    const pass = searchParams.get('p')
    useEffect(() => {
        const handlePasswordLogin = async (email, password) => {
            try {
                const res = await axios.post(process.env.REACT_APP_API_URL + '/login', {
                    email: email,
                    password: password,
                })
                if (res.status === 200) {
                    localStorage.setItem('token', JSON.stringify(res.data.token));
                    const me = await axios.get(process.env.REACT_APP_API_URL + '/user/me', {
                        headers: {
                            Authorization: `Bearer ${res.data.token}`,
                        },
                    });
                    if (me.status === 200) {
                        setGlobalStateWithLocalStorage('user', me.data)
                        setGlobalStateWithLocalStorage('isAuthenticated', true)
                        if (me.data.pending) {
                            navigate(`/admin/user/${me.data.id}`);
                        }else{
                            navigate("/candidate");
                        }
                    }
                } else {
                    navigate("/login");
                }
            } catch (error) {
                console.log(error)
                navigate("/login");
            }

        }
        if (user && pass) {
            handlePasswordLogin(user, pass)
        } else {
            navigate("/login");
        }
    }, [user, pass])
    console.log(user, pass)
    return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#fff' }}>
            <Center maw={400} h={'100%'} mx="auto">
                <Loader size="xl" />
            </Center>
        </div>
    )
}

export default AutoLogin