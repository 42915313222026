import { Link, useMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import ClientJobsListView from "./clientJobsListView";
import api from '../api';
import { useNavigate, createSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
import { ActionIcon, Button, Menu, Box, Group, ScrollArea } from "@mantine/core";
import { Edit, Search, Trash, Dots, Eye } from 'tabler-icons-react';


const ClientJobsList = (props) => {

  const navigate = useNavigate();

  const Actions = ({ id, f }) => {
    return (
      <Group spacing={4} position="right" noWrap>
        <ActionIcon
          name="summary"
          color="green"
          onClick={() => navigate({
            pathname: `/job/${id}`,
            search: createSearchParams({
              tab: 'summary'
            }).toString(),
          })}>
          <Eye size={16} />
        </ActionIcon>
      </Group>
    );

  };

  const clientJobsSetting = {
    resources: `clients/${props.id}/jobs`,
    schema: [
      // { name: 'uid', label: 'Job ID', sort: true, filter: true },
			{ name: 'PositionName', label: 'Position Name', sort: true, filter: true, display: (o, f) => <Link target="_blank" className={"textButton"} to={`/job/${o.ID}?tab=summary`}>{o.PositionName}</Link> },
      { name: 'Headcount', label: 'Position Headcount', sort: true, filter: true, display: (o, f) => <Link target="_blank" className={"textButton"} to={`/job/${o.ID}?tab=summary`}>{o.Headcount}</Link> },
      { name: 'CloseAt', label: 'Close at', sort: true, filter: true, display: (o, f) => o.CloseAt ? dayjs(o.CloseAt).format('DD/MM/YYYY') : "Open" },
    ],
    action: [
    ],
    createAllowed: false,
  };

  if (props.id === undefined) {
    return <></>;
  }

  return (
    <ScrollArea>
      <Box
        sx={(theme) => ({
          backgroundColor: 'white',
          borderRadius: theme.radius.md,
          padding: theme.spacing.sm,
          paddingBottom: 0,
        })}
      >
        <ClientJobsListView clientId={props.id} {...clientJobsSetting} mock={props.mock}/>
      </Box>
    </ScrollArea>
  );
};

export default ClientJobsList;