import { useMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import AdminListView from "../../components/adminListView";
import { useNavigate, createSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
import { ActionIcon, Button, Menu, Box, Group, ScrollArea } from "@mantine/core";
import { Edit, Search, Trash, Dots, Eye, LayoutKanban } from 'tabler-icons-react';
import Header from "../../components/header";


const JobList = (props) => {
  const navigate = useNavigate();

  const Actions = ({ id, f }) => {
    return (
      <Group spacing={4} position="right" noWrap>
        <ActionIcon
          name="board"
          color="blue"
          onClick={() => navigate({
            pathname: `/job/${id}`,
            search: createSearchParams({
              tab: 'candidates'
            }).toString()
          })}>
          <LayoutKanban size={16} />
        </ActionIcon>
        <ActionIcon
          name="summary"
          color="green"
          onClick={() => navigate({
            pathname: `/job/${id}`,
            search: createSearchParams({
              tab: 'summary'
            }).toString()
          })}>
          <Eye size={16} />
        </ActionIcon>
        <ActionIcon
          name="delete"
          color="red"
          onClick={async () => { f.deleteResource(id); }}
        >
          <Trash size={16} />
        </ActionIcon>
      </Group>
    );

  };


  const jobSetting = {
    resources: `jobs`,
    schema: [
      { name: 'PositionName', label: 'Position Name', sort: true, filter: true },
      { name: 'Location', label: 'Working Location', sort: true, filter: true },
      { name: 'CreatedAt', label: 'Created At', sort: true, filter: true, display: (o) => <>{dayjs(o.CreatedAt).format('DD MMM YYYY')}</> },
      { name: '', label: '', display: (o, f) => <Actions id={o.ID} f={f} /> },
    ],
    action: [
    ],
    createAllowed: true,
  };
  return (
    <ScrollArea h={"calc(100vh - 80px)"}>
      <Header>
        Job
      </Header>
      <Box
        sx={(theme) => ({
          backgroundColor: 'transparent',
          display: 'flex',
          justifyContent: 'end',
          padding: theme.spacing.md,
          paddingBottom: 0,
        })}
      >
      </Box>
      <AdminListView {...jobSetting} />
    </ScrollArea>
  );
};

export default JobList;