import { Center, Button, Table, Space, Pagination, Container, Text, Flex, Group, Menu, Input } from '@mantine/core';
import { Plus, Edit, Reload, Filter } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import useCURDStore from '../../hooks/useCURDStore';
import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { useState, useEffect } from 'react';
import { sortBy } from 'lodash';

const CandidateJobsListView = (props) => {
  const { page,
    totalPage,
    loading,
    resources,
    resourcesUpdated,
    listResources,
    deleteResource,
    reloadResources, } = useCURDStore({ resourcesName: props.resources });

  const navigate = useNavigate();

  const [sortStatus, setSortStatus] = useState({});
  const [records, setRecords] = useState();
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    if (resources.jobs) {
      const r = resources.jobs.map(r => {
        return {
          ...r,
          ...r.job
        };
      });
      setRecords(r);
    }
		if (resources) {
      const r = resources.map(r => {
        return {
          ...r,
        };
      });
      setRecords(r);
    }
  }, [resources, resourcesUpdated]);

  return (
    <Container sx={() => ({
      // marginTop: theme.spacing.md,
      maxWidth: 'none',
    })}>
      <div style={{ borderRadius: 3, backgroundColor: '#fff', padding: 10 }}>
        <Flex justify="space-between">
          {
            props.createAllowed &&
            <Button
              size="xs"
              // variant="gradient" gradient={{ from: 'teal', to: 'blue', deg: 60 }}
              onClick={() => navigate('/job/create')}
              leftIcon={<Plus />}
            >
              Create
            </Button>
          }
          <Group mr="xs">
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button
                  size="xs"
                  variant='outline'
                  leftIcon={<Filter />}
                >
                  Filter
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                {
                  props.schema?.map((resource, index) => {
                    if (resource.filter) {
                      return (
                        <Menu.Item key={index} onClick={() => {
                          setFilter((prev) => {
                            if (prev.find((p) => p.name === resource.name)) {
                              return prev.filter((p) => p.name !== resource.name);
                            }
                            return [...prev, resource];
                          });
                        }}>
                          {resource.label}
                        </Menu.Item>
                      );
                    }

                  })
                }
              </Menu.Dropdown>
            </Menu>
            <Button
              size="xs"
              variant='outline'
              onClick={() => reloadResources()}
              leftIcon={<Reload />}
            >
              Reload
            </Button>
          </Group>
        </Flex>
        <Space h="md" />
        {
          filter.length > 0 ? <>
            <Flex justify="space-between">
              <Group spacing={4}>
                {
                  filter.map((fr, index) => {
                    return (
                      <Menu shadow="md" width={200}>
                        <Menu.Target>
                          <Button
                            size="xs"
                            variant='light'
                          >
                            <Text>{fr.label}</Text>{fr.userInput && <>: <Text color="gray" ml="xs" weight="lighter">{fr.userInput}</Text></>}
                          </Button>
                        </Menu.Target>

                        <Menu.Dropdown>
                          {/* TODO: debounce input value */}
                          <Input
                            placeholder="contains..."
                            onChange={(e) => {
                              const value = e.target.value;
                              const data = resources.filter((r) => {
                                setFilter((prev) => {
                                  const newPrev = [...prev];
                                  newPrev[index] = { ...newPrev[index], userInput: value };
                                  return newPrev;
                                });
                                return r[fr.name]?.toLowerCase().includes(value?.toLowerCase());
                              });
                              setRecords(data);
                            }}
                          />
                        </Menu.Dropdown>
                      </Menu>
                    );
                  })}
              </Group>
              <Button mr="xs" size="xs" variant='outline' onClick={() => { setFilter([]); }}>Reset</Button>
            </Flex>
          </> : null
        }
        <Space h="md" />
        <DataTable
          highlightOnHover
          striped
          columns={props.schema?.map((resource, index) => {
            return {
              key: index,
              title: <Text mr={resource.name == 'actions' ? "xs" : "none"}>{resource.label}</Text>,
              accessor: resource.name,
              textAlignment: resource.textAlignment || 'left',
              sortable: resource.sort || false,
              render: (row) => {
                return resource.display && resource.display(row, { deleteResource }) || row[resource.name];
              }
            };
          })}
          records={records}
          sortStatus={sortStatus}
          onSortStatusChange={setSortStatus}

        />
        <Space h="lg" />
        <Center>
          <Pagination total={totalPage} page={page} onChange={(page) => listResources({ _page: page })} color="cyan" radius="md" />
        </Center>

      </div>
    </Container>
  );
};



export default CandidateJobsListView;