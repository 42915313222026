import React, { useState, useEffect } from 'react';
import { Modal, TextInput, Paper, Table, Button, Select } from '@mantine/core';
import { useJobStoreContext } from '../../stores/useStoreContext';
import { useClientStoreContext } from "../../stores/useStoreContext";

const Option = Select.Option;

const AddCandidatesToJobModal = ({ visible, cancel, candidates }) => {
  const [selectedJob, setSelectedJob] = useState(null);
	const [jobPagingInfo, setJobPagingInfo] = useState({
		page: 1,
		total: 0,
		limit: 10,
	}); // [page, total, limit]
  const {
		findJobs,
		setCandidatesToJobByJobID,
	} = useJobStoreContext();
  const [jobList, setJobList] = useState([]);
  const [searchParams, setSearchParams] = useState({});
	const { findClients } = useClientStoreContext();
	const [clients, setClients] = useState([{
		label: 'Loading...',
		value: null,
	}]);

  useEffect(() => {
		const fetchClients = async () => {
			const { data } = await findClients();
			setClients(data.map(c => ({
				label: c.Name,
				value: c.ID,
			})));
		}
		fetchClients();
    fetchJobList();
  }, []);

  const fetchJobList = async (page, searchParams) => {
    const fetchedJobList = await findJobs(page, searchParams);
    setJobList(fetchedJobList.data);
		console.log(fetchedJobList);
		setJobPagingInfo({
			total: fetchedJobList.total,
			limit: fetchedJobList.limit,
			page: fetchedJobList.page,
		});
  };

	const handleSearch = (param, value) => {
		const newSearchParams = { ...searchParams, [param]: value };
		setSearchParams(newSearchParams);
	};

  const handleOk = async () => {
    if (selectedJob) {
      await setCandidatesToJobByJobID(selectedJob, candidates);
			// redirect to the job
			window.location.href = `/job/${selectedJob}?tab=candidates`;
		} else {
			alert('Please select a job');
		}
  };

  return (
    <Modal
      title={`Add ${candidates.length} Candidates to Job`}
      opened={visible}
      onClose={cancel}
      withCloseButton
    >
			<TextInput
				label="Search by name"
				placeholder="Search by name"
				onChange={(event) => handleSearch('name', event.currentTarget.value)}
			/>
			<div>
				<Select
					onChange={async (v) => {
						handleSearch('ClientId', v)
					}}
					label='Client'
					value={searchParams?.ClientId}
					data={clients}
				/>
			</div>
			<Button onClick={() => fetchJobList(1, searchParams || {})} color="blue" fullWidth size="lg" style={{ marginTop: '1em' }}>Search</Button>
			<br />
      <Paper padding="xs" shadow="lg">
        <Table>
          <thead>
            <tr>
              {/* <th>Job Name</th> */}
              <th>Job Title</th>
            </tr>
          </thead>
          <tbody>
            {jobList?.map((job) => (
              <tr
                key={job.ID}
                onClick={() => setSelectedJob(job.ID)}
                style={{ cursor: 'pointer', backgroundColor: selectedJob === job.ID ? '#f0f0f0' : 'white' }}
              >
                {/* <td>{job.job_name}</td> */}
                <td>{job.PositionName}</td>
              </tr>
            ))}
          </tbody>
        </Table>
				<br />
				<div>
					Jump to page:
					<Select
						value={jobPagingInfo.page}
						data={Array.from({ length: Math.ceil(jobPagingInfo.total / jobPagingInfo.limit) }, (_, i) => ({ value: i + 1, label: `${i + 1}` }))}
						onChange={(value) => fetchJobList(value, searchParams || {})}
					/>
				</div>
      </Paper>
      
      <Button onClick={handleOk} color="blue" fullWidth size="lg" style={{ marginTop: '1em' }}>
        Confirm
      </Button>
    </Modal>
  );
};

export default AddCandidatesToJobModal;