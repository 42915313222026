import { Group, UnstyledButton, Menu, Divider, Text, Tabs, ActionIcon, ActionIText, AspectRatio, Image, Box, Checkbox, Space, Title, Input, MediaQuery, MultiSelect, Button, Textarea, Select, Container, Grid, SimpleGrid, CopyButton } from "@mantine/core";
import { Photo } from 'tabler-icons-react';
import { DatePicker } from '@mantine/dates';
import { Dropzone } from "@mantine/dropzone";
import RichTextEditor from "@mantine/rte";
import { useCallback, useEffect, useState } from "react";
import { Edit } from 'tabler-icons-react';
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
import { IconPhoto, IconMessageCircle, IconSettings, IconDotsVertical, IconFile, IconCopy, IconBriefcase } from '@tabler/icons';
import EditableText from "../../components/editableText";
import EditableSelect from "../../components/editableSelect";
import EditableMultiSelect from "../../components/editableMultiSelect";
import ClientJobsList from "../clientJobsList";
import ClientContactsList from "../clientContacts/clientContactList";
import ShareButton from "../../components/share";
import { useFieldStoreContext } from "../../stores/useStoreContext";
import api from '../../api';


const ClientsDetailView = ({
  resource,
  specialities,
  resourcesUpdated,
  updateResource,
  reloadResources
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get('tab') || 'summary';
  const [client, setClient] = useState(resource);
  const [users, setUsers] = useState([]);
  const { client_extra_field } = useFieldStoreContext()
  async function handleEdit(variable, data) {
    if (data == client[variable]) return;
    if (variable === 'Specialities') {
      data = data.map((v) => ({ 'ID': v }));
    }
    setClient({ ...client, [variable]: data });
    updateResource(client.ID, { data: { [variable]: data } });
  }

  useEffect(() => {
    const fetchUsers = async () => {
      const { data } = await api().get('/users');
      setUsers(data.data);
    }
    fetchUsers();
  }, [])

  return (
    <Container sx={(theme) => ({
      marginTop: theme.spacing.md,
      maxWidth: '100%'
    })}>
      <Grid sx={(theme) => ({ borderRadius: 3, backgroundColor: theme.colors.brand[0], padding: theme.spacing.md })}>
        <Grid.Col span={10}>
          <Title order={1} sx={(theme) => ({
            color: theme.colors.black[0],
            paddingBottom: theme.spacing.xs,
            '@media (max-width: 768px)': {
              fontSize: '1.5rem',
              paddingTop: theme.spacing.xs,
            }
          })}>{client?.Name ? client?.Name : 'Client'}</Title>
        </Grid.Col>
        <Grid.Col span={2}>
          <Menu shadow="md" width={200} sx={{ textAlign: "right" }} trigger="click">
            <Menu.Target>
              <Title>
                <UnstyledButton

                  sx={(theme) => ({
                    width: '26px',
                    '@media (max-width: 768px)': {
                      width: '1rem',
                    }
                  })}
                >
                </UnstyledButton>
              </Title>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item icon={<IconSettings size={14} />}
                onClick={() => navigate(`/client/${client.id}/edit`)}
                sx={{ textAlign: "right" }}
              >
                Edit
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Grid.Col>
        <ShareButton />
        <Grid.Col>
          <Tabs defaultValue={tabParam} value={tabParam} onTabChange={t => { setSearchParams({ 'tab': t }); }}>
            <Tabs.List>
              <Tabs.Tab value="summary" icon={<IconPhoto size={14} />}>Summary</Tabs.Tab>
              <Tabs.Tab value="jobs" icon={<IconBriefcase size={14} />}>Jobs</Tabs.Tab>
              <Tabs.Tab value="contacts" icon={<IconBriefcase size={14} />}>Contacts</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="summary" pt="xs">
              <Grid>
                <Grid.Col md={12} sm={12}>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: theme.colors.brand[0],
                      padding: theme.spacing.sm,
                      margin: theme.spacing.sm,
                      borderRadius: theme.radius.md,
                    })}
                  >
                    <Title order={4} sx={(theme) => ({ paddingBottom: theme.spacing.sm })}>
                      Client Details
                    </Title>
                    <Divider sx={(theme) => ({ paddingBottom: theme.spacing.sm })} />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('Name', v);
                      }}
                      label='Name'
                      value={client?.Name}
                    />
                    <EditableMultiSelect
                      onEdit={async (v) => {
                        console.log(v)
                        await handleEdit('Specialities', v);
                      }}
                      label='Specialities'
                      data={specialities?.map(s => ({ label: s.industry + ' - ' + s.speciality, value: s.ID })) || []}
                      value={client.Specialities?.map(s => s.ID) || []}
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('Address', v);
                      }}
                      label='Address'
                      value={client?.Address}
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('Location', v);
                      }}
                      label='Location'
                      value={client?.Location}
                    />
                  </Box>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: theme.colors.brand[0],
                      padding: theme.spacing.sm,
                      margin: theme.spacing.sm,
                      borderRadius: theme.radius.md,
                    })}
                  >
                    <Title order={4} sx={(theme) => ({ paddingBottom: theme.spacing.sm })}>
                      Log Book
                    </Title>
                    <Divider sx={(theme) => ({ paddingBottom: theme.spacing.sm })} />
                    <EditableSelect
                      key={users.length}
                      label='Owner'
                      value={client?.Owner?.id || ''}
                      data={users.length ? users.map((u) => ({ label: u.name, value: u.id })) : []}
                      onEdit={async (v) => {
                        await handleEdit('OwnerId', v);
                      }}
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('Remarks', v);
                      }}
                      label='Remarks'
                      value={client?.Remarks}
                    />
                    <EditableText
                      label='Client Created Date'
                      value={client?.CreatedAt}
                      isDate
                    />
                    <EditableText
                      label='Client Updated Date'
                      value={client?.UpdatedAt}
                      isDate
                    />
                  </Box>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: theme.colors.brand[0],
                      padding: theme.spacing.sm,
                      margin: theme.spacing.sm,
                      borderRadius: theme.radius.md,
                    })}
                  >
                    <Title order={4} sx={(theme) => ({ paddingBottom: theme.spacing.sm })}>
                      Additional Information
                    </Title>
                    {client_extra_field.map((item) => (
                      <EditableText
                        key={item.id}
                        onEdit={async (v) => {
                          await handleEdit(item.label, v);
                        }}
                        label={item.label}
                        value={client[item.label] || ''}
                      />
                    ))}
                  </Box>
                </Grid.Col>
                <Grid.Col md={6} sm={12}>
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
            <Tabs.Panel value="jobs" pt="xs">
              <ClientJobsList id={client?.ID} />
            </Tabs.Panel>
            <Tabs.Panel value="contacts" pt="xs">
              <ClientContactsList id={client?.ID} />
            </Tabs.Panel>
          </Tabs>
        </Grid.Col>
      </Grid>
    </Container>
  );
};
export default ClientsDetailView; 