import { Link, useMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import ClientJobsListView from "../clientJobsListView";
import api from '../../api';
import { useNavigate, createSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
import { ActionIcon, Button, Menu, Box, Group } from "@mantine/core";
import { Edit, Search, Trash, Dots, Eye } from 'tabler-icons-react';
import ClientContactsListView from "./clientContactsListView";
import ClientContactModal from "./clientContactModal";


const ClientContactsList = (props) => {
  const clientContactsSetting = {
		clientId: props.id,
    resources: `clients/${props.id}/contacts`,
    schema: [
			// eslint-disable-next-line jsx-a11y/anchor-is-valid
			{ name: 'displayName', label: 'Display Name', sort: true, filter: true, display: (o, f) => <Link target="_blank" className={"textButton"} to={`/client/${o.ClientID}/contact/${o.ID}`}>{o.displayName}</Link>  },
			{ name: 'firstName', label: 'First Name', sort: true, filter: true, display: (o, f) => <Link className={"textButton"} to={`/client/${o.ClientID}/contact/${o.ID}`}>{o.firstName}</Link> },
			{ name: 'lastName', label: 'Last Name', sort: true, filter: true, display: (o, f) => <Link target="_blank" className={"textButton"} to={`/client/${o.ClientID}/contact/${o.ID}`}>{o.lastName}</Link> },
			{ name: 'position', label: 'Position', sort: true, filter: true, display: (o, f) => <Link target="_blank" className={"textButton"} to={`/client/${o.ClientID}/contact/${o.ID}`}>{o.position}</Link> },
    ],
    action: [
    ],
    createAllowed: true,
  };

  if (props.id === undefined) {
    return <></>;
  }

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: 'white',
          borderRadius: theme.radius.md,
          padding: theme.spacing.sm,
          paddingBottom: 0,
        })}
      >
        <ClientContactsListView {...clientContactsSetting} />
      </Box>
    </>
  );
};

export default ClientContactsList;