import { loadFull } from "tsparticles";
import { Button, Center, Container, Group, Title, Text, Autocomplete, PasswordInput } from '@mantine/core';
import { motion, useAnimation } from "framer-motion"
import { useEffect, useState } from 'react';
import './login.css';
import { useNavigate } from 'react-router-dom';
import { EyeOff, EyeCheck } from 'tabler-icons-react';
import { setGlobalStateWithLocalStorage } from '../stores/store';
import axios from "axios";

const primaryButton = {
	variant: "gradient",
	gradient: { from: 'teal', to: 'lime', deg: 105 },
	radis: "xl"
}

function Login() {
	const controls = useAnimation()
	const navigate = useNavigate();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const data =
		email.trim().length > 0 && !email.includes('@')
			? ['hkes.net', 'gmail.com', 'outlook.com', 'yahoo.com'].map((provider) => `${email}@${provider}`)
			: [];

	const handlePasswordLogin = async (email, password) => {
		const res = await axios.post(process.env.REACT_APP_API_URL + '/login', {
			email: email,
			password: password,
		})
		if (res.status === 200) {
			localStorage.setItem('token', JSON.stringify(res.data.token));
			const me = await axios.get(process.env.REACT_APP_API_URL + '/user/me', {
				headers: {
					Authorization: `Bearer ${res.data.token}`,
				},
			});
			if (me.status === 200) {
				setGlobalStateWithLocalStorage('user', me.data)
				setGlobalStateWithLocalStorage('isAuthenticated', true)
				if (me.data.pending) {
					navigate(`/admin/user/${me.data.id}`);
				}else{
					navigate("/candidate");
				}
			}
		} else {
			handleError(res.data.message);
		}
	}

	//   localStorage.setItem('login', JSON.stringify(data));
	//   setGlobalState('user', data);
	//   setGlobalState('isAuthenticated', true);
	//   navigate("/");
	// }

	const handleError = err => {
		console.log(err)
	}

	const particlesInit = async (main) => {
		await loadFull(main);
	};

	useEffect(() => {
		controls.start("stay")
	}, [])
	return (
		<Container size="md" px="md">
			<Center style={{ height: '100%', width: '100%',overflow:'hidden' }}>
				<Center style={{ width: '100%', marginTop: 30, marginBottom: 20, flexDirection: 'column' }}>
					<Text weight={700} sx={(theme) => ({
						zIndex: '100',
						textAlign: 'center',
						color: 'white',
						fontSize: '60px',
						letterSpacing: 2,
						bold: true,
						width: '450px',
						maxWidth: '100%',
						marginBottom: '20px',
					})}
					>
						HKES
					</Text>
					{/* ENABLE ALONG WITH EMAIL PASSWORD FEATURE */}
					<Autocomplete
						data={data}
						onChange={setEmail}
						// rightSection={true ? <Loader size={16} /> : null}
						label="Email"
						placeholder="Your email"
						sx={{ width: '80%', marginBottom: '20px', label: { color: 'white' } }}
						gutters={true}
					/>
					<PasswordInput
						label="Password"
						placeholder="Your password"
						defaultValue="secret"
						onChange={(event) => setPassword(event.currentTarget.value)}
						visibilityToggleIcon={({ reveal, size }) =>
							reveal ? <EyeOff size={size} /> : <EyeCheck size={size} />
						}
						sx={{ width: '80%', marginBottom: '20px', label: { color: 'white' } }}
					/>
					<Button
						style={{ width: '80%' }}
						onClick={() => handlePasswordLogin(email, password)}
						{...primaryButton}
					>Sign in</Button>
				</Center>
			</Center>
		</Container>
	);
}

export default Login;
