import React, { useEffect, useState } from "react";
import useCURDStore from '../../../hooks/useCURDStore';
import { Modal, Badge, Center, Text, Pagination,Group,Space,Input,Button } from '@mantine/core';
import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { Plus, Edit, Reload, Filter } from 'tabler-icons-react';



const JobAddAssignModal = (props) => {
    const { page,
        totalPage,
        loading,
        resources,
        createResource,
        listResources,
        deleteResource,
        sortResources,
        searchResources,
        reloadResources, } = useCURDStore({
            resourcesName: props.resources,
            mock: props.mock || false,
            mockResources: props.mockResources || [],
            mockResource: props.mockResource || {},
        });
    const { showModal, closeASSignModal,addJob } = props
    const [sortStatus, setSortStatus] = useState({});
    const [records, setRecords] = useState(resources);
	const [search, setSearch] = useState('');


    useEffect(() => {
        sortResources(sortStatus.columnAccessor, sortStatus.direction);
    }, [sortStatus]);
    useEffect(() => {
        setRecords(resources);
    }, [resources]);

    return (
        <>
            <Modal zIndex={201} size='100%' opened={showModal} onClose={closeASSignModal} title="Add JobAssign">
                <div style={{ width: '100%', height: '70vh', overflow: 'auto' }}>
                    <div style={{ width: '100%', minWidth: '600px' }}>
                        {!props.noSearch ? <Group><Input
                            style={{ width: '70vw' }}
                            placeholder="Search..."
                            onChange={(e) => setSearch(e.target.value)}
                        />
                            <Button size="xs" variant='outline' onClick={() => searchResources(search)} leftIcon={<Filter />}>Search</Button>
                        </Group> : null}
                        <Space h="md" />
                        <DataTable
                            highlightOnHover
                            striped
                            columns={props.schema?.map((resource, index) => {
                                return {
                                    key: index.toString(),
                                    title: <Text mr={resource.name == 'actions' ? "xs" : "none"}>{resource.label}</Text>,
                                    width: resource.width,
                                    accessor: resource.name,
                                    textAlignment: resource.textAlignment || 'left',
                                    sortable: resource.sort || false,
                                    render: (row) => {
                                        if (resource.display) {
                                            return resource.display(row, { deleteResource });
                                        } else {
                                            return <>{row[resource.name]}</>;
                                        }
                                    }
                                };
                            })}
                            records={records}
                            sortStatus={sortStatus}
                            onSortStatusChange={setSortStatus}

                        />
                        <Space h="md" />
                        <Center>
                            <Pagination total={totalPage} page={page} onChange={(page) => listResources({ _page: page })} radius="md" />
                        </Center>
                    </div>
                </div>
                <Group position="right">
                    <Button onClick={addJob}>sure</Button>
                </Group>
            </Modal>
        </>
    )
}
export default JobAddAssignModal