import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { MultiSelect, PasswordInput, Container, Grid, Title, Menu, UnstyledButton, Tabs, Box, TextInput, Switch, Select, Button, Card, Group, Text, Center, Divider } from '@mantine/core';
import { EyeOff, EyeCheck } from 'tabler-icons-react';
import QRCode from "react-qr-code";
import { IconSettings } from '@tabler/icons';
import EditableText from '../../components/editableText';
import EditableSelect from '../../components/editableSelect';
import TextEditor from '../../components/richTextEditor';

const ClientContactDetailView = ({
  contactObj,
	createResource,
  resourcesUpdated,
  updateResource
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
	const [password, setPassword] = useState('');
  const [contact, setContact] = useState(contactObj);

	async function handleEdit(variable, data) {
		console.log(contactObj)
    if (data == contactObj[variable]) return;
    setContact({ ...contactObj, [variable]: data });
    updateResource(contactObj.ID, { ...contactObj, [variable]: data });
  }

  return (
    <Container sx={(theme) => ({
      marginTop: theme.spacing.md,
      maxWidth: '100%',
    })}>
      <Grid sx={(theme) => ({ borderRadius: 3, backgroundColor: theme.colors.brand[0], padding: theme.spacing.md })}>
        <Grid.Col span={10}>
          <Title order={1} sx={(theme) => ({
            color: theme.colors.black[0],
						paddingBottom: theme.spacing.xs,
						'@media (max-width: 768px)': {
							fontSize: '1.5rem',
							paddingTop: theme.spacing.xs,
						}
					})}>
						{!!contactObj.ID ? contactObj.displayName : 'Create Contact'}
					</Title>
				</Grid.Col>
				<Grid.Col>
					<Title order={4} sx={(theme) => ({ paddingBottom: theme.spacing.sm })}>
                      Contact Details<br />
											{!!contactObj.ID && <>Contact of <Link className="link" to={`/client/${contactObj.client.ID}?tab=contacts`}>{contactObj.client.Name}</Link></>}
                    </Title>
                    <Divider sx={(theme) => ({ paddingBottom: theme.spacing.sm })} />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('displayName', v);
                      }}
                      label='Display Name'
                      value={contactObj?.displayName}
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('fullName', v);
                      }}
                      label='Full Name'
                      value={contactObj?.fullName}
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('firstName', v);
                      }}
                      label='First Name'
                      value={contactObj?.firstName}
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('lastName', v);
                      }}
                      label='Last Name'
                      value={contactObj?.lastName}
                    />
										<EditableText
											onEdit={async (v) => {
												await handleEdit('position', v);
											}}
											label='Position'
											value={contactObj?.position}
										/>	
										<EditableText
											onEdit={async (v) => {
												await handleEdit('location', v);
											}}
											label='Location'
											value={contactObj?.location}
										/>
										<EditableSelect
										  label='Gender'
										  onEdit={async (v) => {
												await handleEdit('gender', v);
											}}
											value={contactObj?.gender}
											data= {[
												'Male',
												'Female',
												'Other',
											]}
										/>
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('email', v);
                      }}
                      label='Email'
                      value={contactObj?.email}
                    />
										<EditableText
											onEdit={async (v) => {
												await handleEdit('phone', v);
											}}
											label='Phone'
											value={contactObj?.phone}
										/>
										<EditableText
											onEdit={async (v) => {
												await handleEdit('linkedInUrl', v);
											}}
											label='LinkedIn'
											value={contactObj?.linkedInUrl}
										/>
										<EditableText
											onEdit={async (v) => {
												await handleEdit('weChatPhone', v);
											}}
											label='WeChat'
											value={contactObj?.weChatPhone}
										/>
										<h3>Description</h3>
										<Divider sx={(theme) => ({ paddingBottom: theme.spacing.sm })} />
										<TextEditor
											onEdit={async (v) => {
												await handleEdit('description', v);
											}}
											label='Description'
											content={contactObj?.description}
										/>
										{!!contactObj.ID && <>
										<h3>Logbook</h3>
										<Divider sx={(theme) => ({ paddingBottom: theme.spacing.sm })} />
										<EditableText
											label='Created'
											value={contactObj?.CreatedAt}
											isDate
											readonly
										/>
										<EditableText
											label='Updated'
											value={contactObj?.UpdatedAt}
											isDate
											readonly
										/></>}
				</Grid.Col>
			</Grid>
		</Container>
	);
};
export default ClientContactDetailView; 