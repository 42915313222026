import { useState, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import JobDetailView from './jobDetailView';
import api from "../../api";
import { useJobStoreContext } from '../../stores/useStoreContext';
import { ScrollArea } from '@mantine/core';

const JobView = () => {
  const { params: { id: jobId } } = useMatch('/job/:id');
	const { job, getJobByID, setJobByID } = useJobStoreContext(); 
	const [specialities, setSpecialities] = useState([]);

  useEffect(() => {
    getJobByID(jobId);
		console.log('jobId', jobId);
		const fs = async () => api().get('/specialities').then((response) => {
			setSpecialities(response.data);
		});

		fs();
  }, [jobId]);

  if (!job?.ID) return <div>Loading...</div>;

	return <ScrollArea h={"calc(100vh - 80px)"}><JobDetailView {...{
		jobObject: job,
		specialities,
		updateResource: setJobByID,
	}} /></ScrollArea>;
};

export default JobView;