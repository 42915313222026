import React, { useEffect, useState } from "react";
import { Modal, Badge, Center, Text, Pagination, Group, Space, Input, Button } from '@mantine/core';
import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { Plus, Edit, Reload, Filter } from 'tabler-icons-react';



const JobAddAssignModal = (props) => {
    const { showModal, closeModal, modalData, type, isAdd, editFun, addFun } = props
    const [formData, setFormData] = useState({ ...modalData })
    useEffect(() => {
        setFormData(modalData)
    }, [modalData])
    const editOrAddField = () => {
        if(formData.label==='') return
        if (isAdd) {
            addFun({ id: new Date().toString(), label: 'source', type: 'text box', active: true, ...formData })
            closeModal()
        } else {
            editFun(formData)
            closeModal()
        }
    }
    return (
        <>
            <Modal size={window.innerWidth > 720 ? '60%' : '100%'} opened={showModal}
                onClose={closeModal} title={`${isAdd ? 'Add' : 'Edit'} ${type} field`}>
                <Group position="left">
                    <Input onChange={(e) => setFormData({ ...formData, label: e.target.value })} value={formData.label} style={{ width: '70%' }} />
                    <Button onClick={editOrAddField}>sure</Button>
                </Group>
            </Modal>
        </>
    )
}
export default JobAddAssignModal