import React, { useEffect, useState } from 'react';
import {
	Container,
	Text,
	PasswordInput,
	Button,
	MultiSelect,
	Center,
	Input,
	Loader
} from '@mantine/core';
import { useGlobalStateWithLocalStorage } from '../stores/store';
import Header from '../components/header';
import { Space } from '@mantine/core';
import { EyeOff, EyeCheck } from 'tabler-icons-react';
import EditableMultiSelect from '../components/editableMultiSelect';
import axios from "axios";


const EditProfile = () => {
	const user = useGlobalStateWithLocalStorage('user');
	const [name, setName] = useState(user.name);
	const [email, setEmail] = useState(user.Email);
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);

	const handleSubmit = async () => {
		// Handle form submission logic here
		setLoading(true)
		try {
			const res = await axios.post(process.env.REACT_APP_API_URL + '/login', {
				email: email,
				password: password,
			})
			setLoading(false)
		} catch (err) {
			console.log(err)
			setLoading(false)
		}
	};

	const roleData = [
		{ value: 'SUPER_ADMIN', label: 'Super Admin' },
		{ value: 'CANDIDATE_PROVIDER', label: 'Candidate Provider' },
		{ value: 'JOB_OWNER', label: 'Job Owner' },
	];

	const [selectedRoles, setSelectedRoles] = useState([]);

	useEffect(() => {
		if (!user || !user.roles) return;
		const filteredRoles = user.roles.filter((role) => {
			return roleData.find((roleData) => roleData.value === role);
		});
		setSelectedRoles(filteredRoles);
	}, [user.roles.join(',')]);

	return (
		<Container
			sx={(theme) => ({
				marginTop: theme.spacing.md,
				maxWidth: '100%',
				height: '100vh'
			})}
		>

			<Center>
				<div style={{ width: '100%' ,maxWidth:900}} >
					<Input.Label>Name</Input.Label>
					<Input
						value={name}
						onChange={(e) => setName(e.target.value)}
						label="Name"
						placeholder="Your name"
						sx={{ width: '100%', marginBottom: '20px' }}
					/>
					<Input.Label>Email</Input.Label>
					<Input
						label="email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						placeholder="Your email"
						sx={{ width: '100%', marginBottom: '20px' }}
					/>
					<PasswordInput
						label="New Password"
						placeholder="Your new password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						visibilityToggleIcon={({ reveal, size }) =>
							reveal ? <EyeOff size={size} /> : <EyeCheck size={size} />
						}
						sx={{ width: '100%', marginBottom: '20px' }}
					/>
					<EditableMultiSelect
						data={roleData}
						value={selectedRoles}
						label="Role(s):"
						readonly
						placeholder="Select role(s)"
					/>
					<Button
						style={{ width: '100%' }}
						onClick={handleSubmit}
						disabled={loading}
					// Add your primary button styling here
					>
						<span style={{marginRight:'10px'}}>
							Save Changes
						</span>
						{
							loading?(
								<Loader size='xs' color='#fff' />
							):null
						}
					</Button></div>
			</Center>
		</Container>
	);
};

const EditProfilePage = (props) => {
	console.log('test')
	return (
		<>
			<Header>
				Profile
			</Header>
			<Space h="md" />
			<EditProfile />
		</>
	);
};

export default EditProfilePage;