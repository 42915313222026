import useCURDStore from '../../hooks/useCURDStore';
import { useState, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import CandidateDetailsView from './candidateDetailView';
import api from "../../api";
import { notifications } from '@mantine/notifications';
import { Check } from 'tabler-icons-react';
import { ScrollArea } from '@mantine/core';

const CandidateView = () => {
  const { params: { id: candidateId } } = useMatch('/candidate/:id');
  const { page,
    totalPage,
    loading,
    resource,
    resources,
    resourcesUpdated,
    listResources,
    getResource,
    createResource,
    updateResource: _updateResource,
    deleteResource,
    reloadResources, } = useCURDStore({ resourcesName: 'candidates' });
  const [candidateObject, setCandidateObject] = useState({});
	const [functions, setFunctions] = useState([]);
	const [specialities, setSpecialities] = useState([]);

	const updateResource = async (id, payload) => {
		await _updateResource(id, payload);
		notifications.show({
			icon: <Check />,
			message: 'Updated candidate successfully',
		})
	};

  useEffect(() => {
    const fetchCandidate = async () => {
      await getResource(candidateId);
    };
		
		const ff = async () => api().get('/functions').then((response) => {
			setFunctions(response.data);
		});
		const fs = async () => api().get('/specialities').then((response) => {
			setSpecialities(response.data);
		});

		ff();
		fs();
    fetchCandidate();
  }, [candidateId]);

  useEffect(() => {
    const payload = {
      ...resource,
    };
    if (Object.keys(payload).length === 0) return;
    setCandidateObject(payload);
  }, [resource]);

  return <ScrollArea h={"calc(100vh - 80px)"}>{(!candidateObject?.ID) && <div>Loading...</div> ||
    <CandidateDetailsView {...{
			functions,
			specialities,
      candidateObject,
      resourcesUpdated,
      updateResource,
    }} />
  }</ScrollArea>;

};

export default CandidateView;