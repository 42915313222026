import useCURDStore from '../../hooks/useCURDStore';
import { useState, useEffect } from 'react';
import { useMatch } from 'react-router-dom';
import ClientDetailsView from './clientsDetailView';
import api from "../../api";
import { ScrollArea } from '@mantine/core';

const ClientsView = () => {
  const { params: { id: clientId } } = useMatch('/client/:id');
  const { page,
    totalPage,
    loading,
    resource,
    resources,
    resourcesUpdated,
    listResources,
    getResource,
    createResource,
    updateResource,
    deleteResource,
    reloadResources, } = useCURDStore({ resourcesName: 'clients' });
	const [specialities, setSpecialities] = useState([]);

  useEffect(() => {
    const fetchClient = async () => {
      await getResource(clientId);
    };
		const fs = async () => api().get('/specialities').then((response) => {
			setSpecialities(response.data);
		});

		fs();
    fetchClient();
  }, [clientId]);

  return  <ScrollArea h={"calc(100vh - 80px)"}>{(!resource?.ID) && <div>Loading...</div> ||
    <ClientDetailsView {...{
      resource,
			specialities,
      resourcesUpdated,
      updateResource
    }} />
  }</ScrollArea>;

};

export default ClientsView;