import { useCallback, useEffect, useState } from "react";
import { Group, UnstyledButton, Menu, Divider, Text, Tabs, ActionIcon, ActionIText, AspectRatio, Image, Box, Checkbox, Space, Title, Input, MediaQuery, MultiSelect, Button, Textarea, Select, Container, Grid, SimpleGrid, CopyButton, Flex, Modal, Center } from "@mantine/core";
import { DatePicker, DateTimePicker } from '@mantine/dates';
// import { Dropzone } from "@mantine/dropzone";
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import DefaultPdfLayout from "../../components/pdfViewer/defaultPdfLayout";
import { IconPhoto, IconSettings, IconFile, IconBriefcase, IconAssembly } from '@tabler/icons';
import EditableText from "../../components/editableText";
import EditableSelect from "../../components/editableSelect";
import EditableMultiSelect from "../../components/editableMultiSelect";
import CandidateJobsList from "./candidateJobsList";
import EditableDatePicker from "../../components/editableDatePicker";
import QRCode from "react-qr-code";
import api from "../../api";
import { useFieldStoreContext } from "../../stores/useStoreContext";
import ParsingResult from "../../components/parsingResult";
import ShareButton from "../../components/share";


const CandidateDetailsView = ({
	functions,
	specialities,
	candidateObject,
	resourcesUpdated,
	updateResource,
	reloadResources,
	isLoading,
	createCandidate,
}) => {
	const navigate = useNavigate();
	const [cvurlrefreshkey, setCvurlrefreshkey] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams();
	const tabParam = searchParams.get('tab') || 'summary';
	const [candidate, setCandidate] = useState({ ...candidateObject, Assessment: candidateObject.Assessment || [] });
	console.log(candidate)
	const [cvurl, setCvurl] = useState(null);
	const { candidate_extra_field, candidateAssessment_extra_field } = useFieldStoreContext()
	// get all the user data
	const [users, setUsers] = useState([]);
	const loadCandidateCVData = useCallback(async (candidateId) => {
		const { data } = await api().get(`/candidates/${candidateId}/cv`);
		setCvurl(data.url);
		return data;
	}, []);

	useEffect(() => {
		const fetchUsers = async () => {
			const { data } = await api().get('/users');
			setUsers(data.data);
		};
		fetchUsers();
	}, []);

	useEffect(() => {
		//empty the candidate object
		setCandidate({});
		if (candidateObject.hasOwnProperty('Assessment')) {
			setCandidate(candidateObject);
		} else {
			setCandidate({ ...candidateObject, Assessment: [] });
		}
	}, [candidateObject]);

	async function handleEdit(variable, data) {
		// console.log('handleEdit');
		// console.log(variable);
		// console.log(data);
		if (data === candidate[variable]) return;
		if (variable === 'Specialities') {
			data = data.map((v) => ({ 'ID': v }));
		}
		if (variable === 'Functions') {
			data = data.map((v) => ({ 'ID': v }));
		}
		// console.log('data fin', data);
		setCandidate({ ...candidate, [variable]: data });
		updateResource(candidate.ID, { data: { [variable]: data } });
	}
	async function handleAssessmentEdit(assessmentItem, data) {
		// console.log('handleEdit');
		// console.log(variable);
		// console.log(data);
		// if (data === candidate[variable]) return;
		console.log('data fin', candidate);
		setCandidate({ ...candidate, Assessment:JSON.stringify([{ ...assessmentItem, value: data }]) });
		updateResource(candidate.ID, { data: { Assessment: JSON.stringify([{ ...assessmentItem, value: data }])  } });
	}

	const getAssessmentValue = (fieldItem, list) => {
		console.log("list", list)
		let value = ''
		list?.forEach((item) => {
			if (item.id === fieldItem.id) {
				value = item.value
			}
		})
		return value
	}

	return (
		<Container
			sx={(theme) => ({
				marginTop: theme.spacing.md,
				maxWidth: "100%",
			})}
		>
			<Grid
				sx={(theme) => ({
					borderRadius: 3,
					backgroundColor: theme.colors.brand[0],
					padding: theme.spacing.md,
				})}
			>
				<Grid.Col span={10}>
					<Title
						order={1}
						sx={(theme) => ({
							color: theme.colors.black[0],
							paddingBottom: theme.spacing.xs,
							"@media (max-width: 768px)": {
								fontSize: "1.5rem",
								paddingTop: theme.spacing.xs,
							},
						})}
					>
						{candidate.Name ? candidate.Name : "Candidate Name"}
					</Title>
					<ShareButton />
				</Grid.Col>
				<Grid.Col span={2}>
					<Menu
						shadow="md"
						width={200}
						sx={{ textAlign: "right" }}
						trigger="click"
					>
						<Menu.Target>
							<Title>
								<UnstyledButton
									sx={(theme) => ({
										width: "26px",
										"@media (max-width: 768px)": {
											width: "1rem",
										},
									})}
								></UnstyledButton>
							</Title>
						</Menu.Target>

						<Menu.Dropdown>
							<Menu.Item
								icon={<IconSettings size={14} />}
								onClick={() => navigate(`/candidate/${candidate.ID}/edit`)}
								sx={{ textAlign: "right" }}
							>
								Edit
							</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				</Grid.Col>
				<Grid.Col>
					<Tabs
						defaultValue={tabParam}
						value={tabParam}
						onTabChange={(t) => {
							if (t === "resume") {
								// reload the cv data
								async function laodcvdata() {
									await loadCandidateCVData(candidate.ID);
									setCvurlrefreshkey(cvurlrefreshkey + 1);
								}
								laodcvdata();
							}
							setSearchParams({ tab: t });
						}}
					>
						<Tabs.List>
							<Tabs.Tab value="summary" icon={<IconPhoto size={14} />}>
								Summary
							</Tabs.Tab>
							<Tabs.Tab value="resume" icon={<IconFile size={14} />}>
								Resume
							</Tabs.Tab>
							<Tabs.Tab value="jobs" icon={<IconBriefcase size={14} />}>
								Jobs
							</Tabs.Tab>
							<Tabs.Tab value="assessment" icon={<IconAssembly size={14} />}>
								Assessment
							</Tabs.Tab>
						</Tabs.List>

						{isLoading && (
							<Flex
								sx={(theme) => ({
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
									height: "100%",
									minHeight: "30vh",
								})}
							>
								<Box
									sx={(theme) => ({
										color: theme.colors.gray[6],
										textAlign: "center",
										width: "100%",
										fontSize: "1.5rem",
										fontFamily: "monospace",
									})}
								>
									Loading...
								</Box>
							</Flex>
						)}

						{!isLoading && (
							<>
								<Tabs.Panel value="summary" pt="xs">
									<Grid>
										<Grid.Col md={6} sm={12}>
											<Box
												sx={(theme) => ({
													backgroundColor: theme.colors.brand[0],
													padding: theme.spacing.sm,
													margin: theme.spacing.sm,
													borderRadius: theme.radius.md,
												})}
											>
												<Title
													order={4}
													sx={(theme) => ({ paddingBottom: theme.spacing.sm })}
												>
													Candidate Details
												</Title>
												<Divider
													sx={(theme) => ({ paddingBottom: theme.spacing.sm })}
												/>
												<EditableText
													label="ID"
													value={candidate.ID}
													copyable
													readonly
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("FirstName", v);
													}}
													label="First Name"
													value={candidate.FirstName}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("LastName", v);
													}}
													label="Last Name"
													value={candidate.LastName}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("Name", v);
													}}
													label="Name"
													value={candidate.Name}
												/>
												<EditableMultiSelect
													onEdit={async (v) => {
														await handleEdit("Tag", v);
														// console.log(v);
													}}
													label="Tag"
													addNewOption
													value={candidate.Tag}
													data={candidate.Tag?.map((t) => ({
														label: t,
														value: t,
													}))}
												/>
												<EditableDatePicker
													onEdit={async (v) => {
														await handleEdit("Birthdate", v);
													}}
													label="Date of birth"
													value={candidate.Birthdate}
												/>
												<EditableText
													label="Age"
													readonly
													value={
														candidate.Birthdate !== null
															? new Date().getFullYear() -
															new Date(candidate.Birthdate).getFullYear()
															: ""
													}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("Nationality", v);
													}}
													label="Nationality"
													value={candidate.Nationality}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("Location", v);
													}}
													label="Location (Multiple Choice)"
													value={candidate.Location}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("CandidateDescription", v);
													}}
													label="Candidate Description"
													value={candidate.CandidateDescription}
												/>
											</Box>
											<Box
												sx={(theme) => ({
													backgroundColor: theme.colors.brand[0],
													padding: theme.spacing.sm,
													margin: theme.spacing.sm,
													borderRadius: theme.radius.md,
												})}
											>
												<Title
													order={4}
													sx={(theme) => ({ paddingBottom: theme.spacing.sm })}
												>
													Contact
												</Title>
												<Divider
													sx={(theme) => ({ paddingBottom: theme.spacing.sm })}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("email", v);
													}}
													label="Email"
													value={candidate.Email}
													copyable
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("PhoneNumber", v);
													}}
													label="Phone"
													value={candidate.PhoneNumber}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("PhoneNumber2", v);
													}}
													label="Phone 2"
													value={candidate.PhoneNumber2}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("HomeTel", v);
													}}
													label="Home Tel"
													value={candidate.HomeTel}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("Wechat", v);
													}}
													label="Wechat"
													value={candidate.Wechat}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("Whatsapp", v);
													}}
													label="Whatsapp"
													value={candidate.Whatsapp}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("Skype", v);
													}}
													label="Skype"
													value={candidate.Skype}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("Linkedin", v);
													}}
													label="Linkedin"
													value={candidate.Linkedin}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("RedBook", v);
													}}
													label="小紅書"
													value={candidate.RedBook}
												/>
											</Box>
											<Box
												sx={(theme) => ({
													backgroundColor: theme.colors.brand[0],
													padding: theme.spacing.sm,
													margin: theme.spacing.sm,
													borderRadius: theme.radius.md,
												})}
											>
												<Title
													order={4}
													sx={(theme) => ({ paddingBottom: theme.spacing.sm })}
												>
													Log Book
												</Title>
												<Divider
													sx={(theme) => ({ paddingBottom: theme.spacing.sm })}
												/>
												<EditableSelect
													key={users.length}
													label="Owner"
													value={candidate?.Owner?.id || ""}
													data={
														users.length
															? users.map((u) => ({
																label: u.name,
																value: u.id,
															}))
															: []
													}
													onEdit={async (v) => {
														await handleEdit("OwnerId", v);
													}}
												/>
												<EditableText
													label="Source"
													value={candidate.Source}
													readonly
												/>
												<EditableText
													label="Created"
													value={candidate.CreatedAt}
													isDate
													readonly
												/>
												<EditableText
													label="Updated"
													value={candidate.UpdatedAt}
													isDate
													readonly
												/>
											</Box>
										</Grid.Col>
										<Grid.Col md={6} sm={12}>
											<Box
												sx={(theme) => ({
													backgroundColor: theme.colors.brand[0],
													padding: theme.spacing.sm,
													margin: theme.spacing.sm,
													borderRadius: theme.radius.md,
												})}
											>
												<Title
													order={4}
													sx={(theme) => ({ paddingBottom: theme.spacing.sm })}
												>
													Categories
												</Title>
												<Divider
													sx={(theme) => ({ paddingBottom: theme.spacing.sm })}
												/>
												<EditableMultiSelect
													onEdit={async (v) => {
														await handleEdit("Specialities", v);
													}}
													label="Specialities"
													data={
														specialities?.map((s) => ({
															label: s.industry + " - " + s.speciality,
															value: s.ID,
														})) || []
													}
													value={candidate.Specialities?.map((s) => s.ID) || []}
												/>
												{candidate.Industries?.length > 0 && (
													<EditableMultiSelect
														label="Industries"
														value={candidate.Industries}
														data={
															candidate.Industries?.map((s) => ({
																label: s,
																value: s,
															})) || []
														}
														readonly
													/>
												)}
												<EditableMultiSelect
													onEdit={async (v) => {
														await handleEdit("Functions", v);
													}}
													label="Functions"
													data={
														functions?.map((s) => ({
															label: s.name,
															value: s.ID,
														})) || []
													}
													value={candidate.Functions?.map((s) => s.ID) || []}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("FunctionDescription", v);
													}}
													label="Functions Description"
													value={
														!!candidate.FunctionDescription ||
															!!candidate.Remarks
															? candidate.FunctionDescription +
															(candidate.Remarks
																? " "
																: "" + candidate.Remarks) || ""
															: null
													}
												/>
												<EditableMultiSelect
													onEdit={async (v) => {
														await handleEdit("Skills", v);
													}}
													label="Skills"
													addNewOption
													value={candidate.Skills}
													data={candidate.Skills?.map((s) => ({
														label: s,
														value: s,
													}))}
												/>
											</Box>
											<Box
												sx={(theme) => ({
													backgroundColor: theme.colors.brand[0],
													padding: theme.spacing.sm,
													margin: theme.spacing.sm,
													borderRadius: theme.radius.md,
												})}
											>
												<Title
													order={4}
													sx={(theme) => ({ paddingBottom: theme.spacing.sm })}
												>
													Education
												</Title>
												<Divider
													sx={(theme) => ({ paddingBottom: theme.spacing.sm })}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("PhD", v);
													}}
													label="PhD"
													value={candidate.PhD}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("Master", v);
													}}
													label="Master"
													value={candidate.Master}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("Degree", v);
													}}
													label="Degree"
													value={candidate.Degree}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("Diploma", v);
													}}
													label="Diploma"
													value={candidate.Diploma}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("University", v);
													}}
													label="University"
													value={candidate.University}
												/>
											</Box>
											<Box
												sx={(theme) => ({
													backgroundColor: theme.colors.brand[0],
													padding: theme.spacing.sm,
													margin: theme.spacing.sm,
													borderRadius: theme.radius.md,
												})}
											>
												<Title
													order={4}
													sx={(theme) => ({ paddingBottom: theme.spacing.sm })}
												>
													Additional Information
												</Title>
												<Divider
													sx={(theme) => ({ paddingBottom: theme.spacing.sm })}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("CurrentPosition", v);
													}}
													label="Current Position"
													value={candidate.CurrentPosition}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("LatestCompany", v);
													}}
													label="Latest Company"
													value={candidate.LatestCompany}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("ReferrerName", v);
													}}
													label="Referrer Name"
													value={candidate.ReferrerName}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("ReferrerEmail", v);
													}}
													label="Referrer Email"
													value={candidate.ReferrerEmail}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("CurrentSalary", v);
													}}
													label="Current Package"
													value={candidate.CurrentSalary}
													currency
												/>
												<EditableSelect
													onEdit={async (v) => {
														await handleEdit("CurrentCurrencyCode", v);
													}}
													label="Package Currency"
													value={candidate.CurrentCurrencyCode}
													data={[
														{ label: "HKD", value: "HKD" },
														{ label: "CNY", value: "CNY" },
														{ label: "USD", value: "USD" },
														{ label: "EUR", value: "EUR" },
														{ label: "JPY", value: "JPY" },
														{ label: "GBP", value: "GBP" },
														{ label: "AUD", value: "AUD" },
														{ label: "CAD", value: "CAD" },
														{ label: "SGD", value: "SGD" },
														{ label: "MYR", value: "MYR" },
													]}
												/>
												<EditableText
													onEdit={async (v) => {
														await handleEdit("ExpectedSalary", v);
													}}
													label="Expected Package"
													value={candidate.ExpectedSalary}
													currency
												/>
												<EditableSelect
													onEdit={async (v) => {
														await handleEdit("ExpectedCurrencyCode", v);
													}}
													label="Expected Currency"
													value={candidate.ExpectedCurrencyCode}
													data={[
														{ label: "HKD", value: "HKD" },
														{ label: "CNY", value: "CNY" },
														{ label: "USD", value: "USD" },
														{ label: "EUR", value: "EUR" },
														{ label: "JPY", value: "JPY" },
														{ label: "GBP", value: "GBP" },
														{ label: "AUD", value: "AUD" },
														{ label: "CAD", value: "CAD" },
														{ label: "SGD", value: "SGD" },
														{ label: "MYR", value: "MYR" },
													]}
												/>
												{candidate_extra_field.map((item) => (
													<EditableText
														key={item.id}
														onEdit={async (v) => {
															await handleEdit(item.label, v);
														}}
														label={item.label}
														value={candidate[item.label]}
													/>
												))}
											</Box>
										</Grid.Col>
									</Grid>
								</Tabs.Panel>
								<Tabs.Panel value="resume" pt="xs">
									<ParsingResult
										candidateId={candidate.ID}
										loadCandidateCVData={loadCandidateCVData}
										overrideCandidateData={async (label, data) => {
											console.log("overrideCandidateData", label, data);
											await handleEdit(label, data);
										}}
										setCandidate={(c) => {
											setCandidate({
												...candidate,
												...c,
											});
											updateResource(candidate.ID, {
												data: {
													...candidate,
													...c,
												},
											});
											const temp = cvurl;
											setCvurl(null);
											setCvurl(temp);
										}}
										isNewCandidate={!!createCandidate}
									/>
									<DefaultPdfLayout
										key={cvurlrefreshkey}
										candidateId={candidate.ID || "new"}
										fileUrl={cvurl || null}
										handleDrop={async (resume) => {
											setCvurl(resume.url);
											await handleEdit("ID", resume.uid);
										}}
									/>
								</Tabs.Panel>
								<Tabs.Panel value="jobs" pt="xs">
									<CandidateJobsList uid={candidate.ID} />
								</Tabs.Panel>
								<Tabs.Panel value="assessment" pt="xs">

									{candidateAssessment_extra_field.map((item) => (
										<EditableText
											key={item.id}
											onEdit={async (v) => {
												await handleAssessmentEdit(item, v);
											}}
											label={item.label}
											value={getAssessmentValue(item, JSON.parse(candidate.Assessment))}
										/>
									))}
								</Tabs.Panel>
							</>
						)}
					</Tabs>
				</Grid.Col>
			</Grid>
		</Container>
	);
};
export default CandidateDetailsView; 