import useCURDStore from '../../hooks/useCURDStore';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useNavigate, createSearchParams } from 'react-router-dom';
import ClientsDetailView from './clientsDetailView';
import { Button, Flex, Paper, ScrollArea } from '@mantine/core';
import { IconAlertCircle, IconUserPlus } from '@tabler/icons';
import { notifications } from '@mantine/notifications';
import { Check } from 'tabler-icons-react';
import { IconFilePlus } from '@tabler/icons-react';
import api from "../../api";

const ClientsCreate = () => {

  const navigate = useNavigate();

  const { page,
    totalPage,
    loading,
    resource,
    resources,
    setPage,
    resourcesUpdated,
    listResources,
    getResource,
    createResource,
    updateResource,
    deleteResource, } = useCURDStore({ resourcesName: 'clients' });
		const [dirty, setDirty] = useState(false);
		const [specialities, setSpecialities] = useState([]);
		const [clientObject, setClientObject] = useState({
			Name: '',
			Specialities: [],
			Address: '',
			Location: '',
			Remark: '',
			created_at: '',
			updated_at: '',
		})

		useEffect(() => {
			const fs = async () => api().get('/specialities').then((response) => {
				setSpecialities(response.data);
			});
	
			fs();
		}, []);

		return (<ScrollArea h={"calc(100vh - 80px)"}>
			{dirty &&
				<Flex
					direction="row"
					// justify="space-between"
					justify="center"
					align="center"
					padding="xs"
					width="100%"
					sx={() => ({
						backgroundColor: 'rgba(255, 0, 0, 0.1)',
					})}
				>
					<Paper color="red" sx={() => {
						return ({
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'row',
							margin: '1rem 2rem',
							backgroundColor: 'transparent',
						})
					}}>
					<IconAlertCircle size="1.5rem" />&nbsp;Click save client to save</Paper>
					<Button onClick={async () => {
						const id = await createResource({
							...clientObject,
						});
						navigate(`/client/${id}`);
						notifications.show({
							icon: <Check />,
							message: 'Created client successfully',
						})
					}}><IconFilePlus/> &nbsp;Save Client</Button>
				</Flex>
		}
		<ClientsDetailView {...{
			specialities,
			resource: clientObject,
			resourcesUpdated,
			updateResource: (_, client) => {
				setDirty(true);
				setClientObject({
					...clientObject,
					...client.data,
				});
				},
			}} />)
	</ScrollArea>
	)
};

export default ClientsCreate;