import React from "react";
import useCURDStore from "../../hooks/useCURDStore";
import { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import ClientContactDetailView from "./clientContactDetailView";

const ClientContactView = () => {
	const {
		params: { id: contactId, clientid: clientId },
	} = useMatch("client/:clientid/contact/:id");
	const {
		page,
		totalPage,
		loading,
		resource,
		resources,
		resourcesUpdated,
		listResources,
		getResource,
		createResource,
		updateResource,
		deleteResource,
		reloadResources,
	} = useCURDStore({
		resourcesName: `clients/${clientId}/contacts`,
		defaultValue: {
		},
	});

	useEffect(() => {
		const fetchCandidate = async () => {
			if (contactId === "create") return;
			await getResource(contactId);
		};
		fetchCandidate();
		console.log('here')
	}, [contactId]);

	return (
		<>
			{(!resource?.ID && contactId !== "create" && <div>Loading...</div>) || (
				<ClientContactDetailView
					{...{
						contactObj: resource,
						resourcesUpdated,
						updateResource,
						createResource,
					}}
				/>
			)}
		</>
	);
};

export default ClientContactView;
