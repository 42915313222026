import React, { useState } from "react";
import { Badge, ActionIcon, Flex, Space, Container, Input, Group, Table, Switch, Modal, Button, ScrollArea } from "@mantine/core";
import { IconX, IconSquarePlus } from '@tabler/icons-react';
import Header from "../../components/header";

const Keywords = () => {
    const [keywordsList, setKeywordsList] = useState([
			{ id: '1', keyword: 'Project management' },
			{ id: '2', keyword: 'Data analysis' },
			{ id: '3', keyword: 'Software development' },
			{ id: '4', keyword: 'Digital marketing' },
			{ id: '5', keyword: 'Customer service' },
			{ id: '6', keyword: 'Financial planning' },
			{ id: '7', keyword: 'Graphic design' },
			{ id: '8', keyword: 'Content writing' },
			{ id: '9', keyword: 'Sales strategy' },
			{ id: '10', keyword: 'UI/UX design' },
			{ id: '11', keyword: 'Database management' },
			{ id: '12', keyword: 'Supply chain optimization' },
			{ id: '13', keyword: 'Machine learning' },
			{ id: '14', keyword: 'Quality assurance' },
			{ id: '15', keyword: 'Network security' },
			{ id: '16', keyword: 'Business intelligence' },
			{ id: '17', keyword: 'Agile methodologies' },
			{ id: '18', keyword: 'Product development' },
			{ id: '19', keyword: 'Risk management' },
			{ id: '20', keyword: 'Search engine optimization (SEO)' },
			{ id: '21', keyword: 'Cloud computing' },
			{ id: '22', keyword: 'Human resources management' },
			{ id: '23', keyword: 'Market research' },
			{ id: '24', keyword: 'Regulatory compliance' },
			{ id: '25', keyword: 'Social media management' }
		])
		const [pageList, setPageList] = useState([
			{ 
					pagename: 'Candidate Provider', 
					function: [{ name: 'candidate', state: false }, { name: 'job', state: true }, { name: 'client', state: false }, { name: 'own', state: true }],
					create: [{ name: 'candidate', state: true }, { name: 'job', state: false }, { name: 'client', state: false }],
					edit: [{ name: 'candidate', state: false }, { name: 'job', state: false }, { name: 'client', state: false }, { name: 'own', state: true }]
			},
			{ 
					pagename: 'Candidate', 
					function: [{ name: 'candidate', state: false }, { name: 'job', state: false }, { name: 'client', state: false }, { name: 'own', state: false }],
					create: [{ name: 'candidate', state: false }, { name: 'job', state: false }, { name: 'client', state: false }],
					edit: [{ name: 'candidate', state: false }, { name: 'job', state: false }, { name: 'client', state: false }, { name: 'own', state: true }]
			},
			{ 
					pagename: 'Job Owner', 
					function: [{ name: 'candidate', state: true }, { name: 'job', state: false }, { name: 'client', state: false }, { name: 'own', state: true }],
					create: [{ name: 'candidate', state: false }, { name: 'job', state: false }, { name: 'client', state: false }],
					edit: [{ name: 'candidate', state: false }, { name: 'job', state: true }, { name: 'client', state: false }, { name: 'own', state: true}]
			},
			{ 
					pagename: 'Job Client', 
					function: [{ name: 'candidate', state: false }, { name: 'job', state: false }, { name: 'client', state: false }, { name: 'own', state: true }],
					create: [{ name: 'candidate', state: false }, { name: 'job', state: false }, { name: 'client', state: false }],
					edit: [{ name: 'candidate', state: false }, { name: 'job', state: false }, { name: 'client', state: false }, { name: 'own', state: true}]
			},
	])
    const [addKeywordValue, setAddKeywordValue] = useState('')
    const [modalValue, setModalValue] = useState('')
    const [modalID, setModalID] = useState('')
    const [showModal, setShowModal] = useState(false)
    const deleteKeyWord = (index) => {
        const newKeywordsList = keywordsList
        newKeywordsList.splice(index, 1)
        setKeywordsList([...newKeywordsList])
    }
    const removeButton = (index) => (
        <ActionIcon
            onClick={() => deleteKeyWord(index)}
            size="xs" color="blue" radius="xl" variant="transparent">
            <IconX size='xs' />
        </ActionIcon>
    );

    const addKeyWords = () => {
        if (addKeywordValue === '') return
        setKeywordsList([...keywordsList, { id: (keywordsList.length + 1).toString(), keyword: addKeywordValue }])
        setAddKeywordValue('')
    }
    const onSwitch = (e, ename, iname, type) => {
			const newPageList = pageList.map(item => {
					if (item.pagename === ename) {
							return {
									...item,
									[type]: item[type].map(jtem => 
											jtem.name === iname ? { ...jtem, state: e.currentTarget.checked } : jtem
									)
							};
					}
					return item;
			});
			setPageList(newPageList);
	}
	
	const rows = (data) => {
    return data.map((element) => (
        <tr key={element.pagename}>
            <td>{element.pagename}</td>
            {['function', 'create', 'edit'].map(actionType => (
                <td valign={"top"} key={actionType}>
                        {element[actionType].map((item) => (
                            <Switch
                                key={item.name}
                                onChange={(e) => onSwitch(e, element.pagename, item.name, actionType)}
                                label={item.name}
                                size="md"
                                checked={item.state}
                                onLabel="ALLOW" offLabel="BAN"
                            />
                        ))}
                </td>
            ))}
        </tr>
    ));
}

    const showEditModal = (id, keyword) => {
        setModalID(id)
        setModalValue(keyword)
        setShowModal(true)
    }
    const saveKeyword = () => {
        if (modalValue === '') return
        const newKeywordsList = keywordsList
        newKeywordsList.forEach((item) => {
            if (item.id === modalID) {
                item.keyword = modalValue
            }
        })
        setKeywordsList([...newKeywordsList])
        closeModal()
    }
    const closeModal = () => {
        setShowModal(false);
        setModalValue('');
        setModalID('')
    }
    return (
			<ScrollArea h={"calc(100vh - 80px)"}>
        <div style={{ width: '100%', height: '100%' }}>
            <Modal zIndex={201} size='350px'
                opened={showModal} onClose={() => closeModal()} title="Edit Keyword">
                <Input
                    onChange={e => setModalValue(e.target.value)}
                    styles={(theme) => ({
                        input: { width: '100%' },
                    })}
                    value={modalValue} />
                <Space h="md" />
                <Group position="right">
                    <Button onClick={saveKeyword}>Done</Button>
                </Group>
            </Modal>
            <Header>
                Keywords
            </Header>
            <Space h="md" />
            <Container size="100%" px={'1rem'}>
                <Flex
                    mih={50}
                    gap="md"
                    justify="flex-start"
                    align="flex-start"
                    direction="row"
                    wrap="wrap"
                >
                    {
                        keywordsList.map((item, index) =>
                            <Badge color="indigo" key={item.id} size="lg" variant="outline" rightSection={removeButton(index)}>
                                <span onClick={() => showEditModal(item.id, item.keyword)}>{item.keyword}</span>
                            </Badge>
                        )
                    }
                </Flex>
                <Space h="md" />
                <Group spacing={0}>
                    <Input
                        value={addKeywordValue}
                        onChange={(e) => setAddKeywordValue(e.target.value)}
                        styles={(theme) => ({
                            input: {
                                width: '250px'
                            },
                        })} variant="outline" size="xs" />
                    <ActionIcon size="md" color="cyan" variant="transparent" onClick={addKeyWords}>
                        <IconSquarePlus size='md' />
                    </ActionIcon>
                </Group>
            </Container>
            <Space h="md" />
            <Header>
                Function switch
            </Header>
            <Space h="md" />
            <Container size="100%" px={'1rem'}>
                <Table>
                    <thead>
                        <tr>
                            <th>Role</th>
                            <th>List all</th>
                            <th>Create</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>{rows(pageList)}</tbody>
                </Table>
            </Container>

        </div></ScrollArea>
    )
}
export default Keywords