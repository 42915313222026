import React, { useEffect, useCallback, useState } from "react"
import JobAddAssignModal from './job/detailsTabs/jobAddAssignModal'
import dayjs from 'dayjs';
import { DateTimePicker } from '@mantine/dates';
import { Trash } from 'tabler-icons-react';
import { IconTrash, IconEdit, IconX } from '@tabler/icons';
import { Group, Space, Button, ActionIcon, Table, Box, Modal, Checkbox, Badge, Container, Input, Accordion } from "@mantine/core";
import { ScrollArea } from '@mantine/core';
import { notifications } from '@mantine/notifications';

const GroupUser = () => {
    const localGroupList=localStorage.getItem('groupList')
    const [showGroup, setShowGroup] = useState(false)
    const [isAddGroup, setIsAddGroup] = useState(false)
    const [groupName, setGroupName] = useState('')
    const [selectName, setSelectName] = useState('')
    const [groupList, setGroupList] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [checkList, setCheckList] = useState([])
    useEffect(()=>{
        if (localGroupList === null) {
            setGroupList([])
        }else{
            setGroupList(JSON.parse(localGroupList))
        }
    },[localGroupList])
    const closeASSignModal = () => {
        setShowModal(false)
        setCheckList([])
    }
    const showAssignModal = (name) => {
        setShowModal(true)
        setSelectName(name)
    }
    const addUser = (name) => {
        if (!checkList.length) return
        const newGroupList = groupList
        newGroupList.forEach((item) => {
            if (item.name === name) {
                console.log(item.name)
                const oldList = item.userList
                const newAddUserList = []
                checkList.forEach((jtem) => {
                    if (oldList.filter((ktem) => ktem.id === jtem.id).length === 0) {
                        newAddUserList.push({
                            ...jtem,
                            expiryDate: dayjs().format('YYYY-MM-DD HH:mm')
                        })
                    }
                })
                item.userList = [...oldList, ...newAddUserList]
            }
        })
        localStorage.setItem('groupList', JSON.stringify([...newGroupList]))
        setGroupList([...newGroupList])
        setShowModal(false)
        setCheckList([])

    }
    const dateChange = (id, date, name) => {
        const newGroupList = groupList
        newGroupList.forEach((item) => {
            if (item.name === name) {
                const dateString = dayjs(date).format('YYYY-MM-DD HH:mm')
                const newUserList = item.userList
                newUserList.forEach((jtem) => {
                    if (jtem.id === id) {
                        jtem.expiryDate = dateString
                    }
                })
            }
        })
        localStorage.setItem('groupList', JSON.stringify([...newGroupList]))
        setGroupList([...newGroupList])
    }
    const checkBoxChange = (e, o) => {
        if (e.currentTarget.checked) {
            setCheckList([...checkList, o])
        } else {
            let _index = 0
            checkList.forEach((item, index) => {
                if (item.id === o.id) {
                    _index = index
                }
            })
            const newCheckList = checkList
            newCheckList.splice(_index, 1)
            console.log(newCheckList)
            setCheckList(JSON.parse(JSON.stringify(newCheckList)))
        }
    }
    const userSetting = {
        resources: `users`,
        schema: [
            {
                name: '', label: '', textAlignment: 'left',
                display: (o) =>
                    <Checkbox checked={checkList.filter((item) => item.id === o.id).length} onChange={(e) => checkBoxChange(e, o)} />
            },
            { name: 'name', label: 'name', sort: true, filter: true },
            {
                name: 'email', label: 'email', sort: true, filter: true, display: (o) => <div>{
                    o.email
                } {!!o.isNew ? <Badge color="red">PENDING</Badge> : <></>}</div>
            },
            { name: 'roles', label: 'roles', sort: true, filter: false, display: (o) => <div>{o.roles.map(r => <Badge radius="xs" variant="outline">{r}</Badge>)}</div> },
        ],
        action: [
        ],
        createAllowed: true,
    };
    const deleteUser = (id, name) => {
        const newGroupList = groupList
        newGroupList.forEach((item) => {
            if (item.name === name) {
                const newUserList = item.userList
                let sIndex = 0;
                newUserList.forEach((jtem, index) => {
                    if (jtem.id === id) {
                        sIndex = index
                    }
                })
                newUserList.splice(sIndex, 1)
                item.userList = newUserList
            }
        })
        localStorage.setItem('groupList', JSON.stringify([...newGroupList]))
        setGroupList([...newGroupList])
    }
    const groupNameAdd = () => {
        if (groupName === '') return
        const newGroupList = groupList
        if (newGroupList.filter((item) => item.name === groupName).length) {
            notifications.show({
                icon: <IconX />,
                message: 'Duplicate group names',
                color: "red"
            })
            return
        }
        if (isAddGroup) {
            newGroupList.push({ name: groupName, userList: [] })
            localStorage.setItem('groupList', JSON.stringify([...newGroupList]))
            setGroupList(newGroupList)
            setShowGroup(false)
            setGroupName('')
        } else {
            newGroupList.forEach((item) => {
                if (item.name === selectName) {
                    item.name = groupName
                }

            })
            localStorage.setItem('groupList', JSON.stringify([...newGroupList]))
            setGroupList([...newGroupList])
            setShowGroup(false)
            setGroupName('')
            setSelectName('')
        }
    }
    const deleteGroup = (name) => {
        const newGroupList = groupList
        let sIndex = 0;
        newGroupList.forEach((item, index) => {
            if (item.name === name) {
                sIndex = index
            }
        })
        newGroupList.splice(sIndex, 1)
        localStorage.setItem('groupList',JSON.stringify([...newGroupList]))
        setGroupList([...newGroupList])
    }
    const editGroupName = (name) => {
        setGroupName(name)
        setSelectName(name)
        setIsAddGroup(false)
        setShowGroup(true)
    }
    function AccordionControl(accordionProps) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Accordion.Control {...accordionProps} />
                <ActionIcon size="lg" onClick={() => editGroupName(accordionProps.children[0])} >
                    <IconEdit color='green' size="1rem" />
                </ActionIcon>
                <ActionIcon size="lg" onClick={() => deleteGroup(accordionProps.children[0])}>
                    <IconTrash color='red' size="1rem" />
                </ActionIcon>
            </Box>
        );
    }
    const rows = (data, name) => {
        return data.map((element) => (
            <tr key={element.id}>
                <td>{element.name}</td>
                <td>{element.email}</td>
                <td>{element.roles?.map(r => <Badge key={r.toString()} radius="xs" variant="outline">{r}</Badge>)}</td>
                <td>
                    <DateTimePicker
                        valueFormat='YYYY-MM-DD HH:mm'
                        value={new Date(element.expiryDate)}
                        onChange={(value) => dateChange(element.id, value, name)}
                        placeholder="Pick date"
                    />
                </td>
                <td>
                    <Group spacing={4} position="right" noWrap>
                        <ActionIcon
                            name="delete"
                            color="red"
                            onClick={() => deleteUser(element.id, name)}
                        >
                            <Trash size={16} />
                        </ActionIcon>
                    </Group>
                </td>
            </tr>
        ));
    }
    return (
        <ScrollArea h={"calc(100vh - 80px)"}>
            <Modal size='350px' opened={showGroup} onClose={() => { setShowGroup(false); setGroupName('');setSelectName('') }} title={`${isAddGroup ? 'Add' : 'Edit'} Group`}>
                <Group>
                    <Input value={groupName} onChange={(e) => { setGroupName(e.target.value) }} />
                    <Button onClick={groupNameAdd}>Done</Button>
                </Group>
            </Modal>
            <JobAddAssignModal {...userSetting} showModal={showModal} closeASSignModal={closeASSignModal} addJob={() => addUser(selectName)} />
            <Container px='.5rem'>
                <Space h='xs' />
                <Button
                    size='xs'
                    onClick={() => { setShowGroup(true); setIsAddGroup(true) }}
                >
                    Add Group
                </Button>
                <Space h='xs' />
                <Accordion chevronPosition="left" variant="contained" radius="md">
                    {
                        groupList.map((item) =>
                            <Accordion.Item key={item.name} value={item.name}>
                                <AccordionControl>{item.name}/{item.userList.length}</AccordionControl>
                                <Accordion.Panel>
                                    {
                                        item.userList.length ? (
                                            <Table style={{ background: '#fff' }}>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Roles</th>
                                                        <th>Expiry Date</th>
                                                        <th>Active</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{rows(item.userList, item.name)}</tbody>
                                            </Table>
                                        ) : null
                                    }
                                    <Space h='xs' />
                                    <Group position="center">
                                        <Button onClick={() => showAssignModal(item.name)}>Add User</Button>
                                    </Group>
                                </Accordion.Panel>
                            </Accordion.Item>
                        )
                    }
                </Accordion>
            </Container>

        </ScrollArea>
    )
}
export default GroupUser