import useCURDStore from '../../hooks/useCURDStore';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useNavigate, createSearchParams, useMatch } from 'react-router-dom';
import { Button, Flex, Paper } from '@mantine/core';
import { IconAlertCircle, IconUserPlus } from '@tabler/icons';
import { notifications } from '@mantine/notifications';
import { Check } from 'tabler-icons-react';
import { IconFilePlus } from '@tabler/icons-react';
import ClientContactDetailView from './clientContactDetailView';

const ClientContactCreate = () => {
	const {
		params: { id: contactId, clientid: clientId },
	} = useMatch("client/:clientid/contact/:id");

  const navigate = useNavigate();

  const { page,
    totalPage,
    loading,
    resource,
    resources,
    setPage,
    resourcesUpdated,
    listResources,
    getResource,
    createResource,
    updateResource,
    deleteResource, } = useCURDStore({ resourcesName: `clients/${clientId}/contacts/new` });
		const [dirty, setDirty] = useState(false);
		const [specialities, setSpecialities] = useState([]);
		const [clientObject, setClientObject] = useState({
			ClientID: clientId,
			displayName: '',
			fullName: '',
			firstName: '',
			lastName: '',
			position: '',
			location: '',
			gender: '',
			email: '',
			phone: '',
			linkedInUrl: '',
			weChatPhone: '',
			description: '',
		})

		return (<>
			{dirty &&
				<Flex
					direction="row"
					// justify="space-between"
					justify="center"
					align="center"
					padding="xs"
					width="100%"
					sx={() => ({
						backgroundColor: 'rgba(255, 0, 0, 0.1)',
					})}
				>
					<Paper color="red" sx={() => {
						return ({
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'row',
							margin: '1rem 2rem',
							backgroundColor: 'transparent',
						})
					}}>
					<IconAlertCircle size="1.5rem" />&nbsp;Click save contact to save</Paper>
					<Button onClick={async () => {
						const id = await createResource({
							...clientObject,
						});
						navigate(`/client/${clientId}/contact/${id}`);
						notifications.show({
							icon: <Check />,
							message: 'Created Contact successfully',
						})
					}}><IconFilePlus/> &nbsp;Save Contact</Button>
				</Flex>
		}
		<ClientContactDetailView {...{
			contactObj: clientObject,
			updateResource: (_, client) => {
				setDirty(true);
				setClientObject({
					...clientObject,
					...client,
				});
				},
			}} />
	</>
	)
};

export default ClientContactCreate;