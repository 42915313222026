import { Container, Title, Menu, Input, Flex, Box, Text, Button, Popover, MultiSelect, UnstyledButton, Group, ActionIcon, Modal, TextInput, Space, SimpleGrid } from '@mantine/core';
import { useState, useEffect } from 'react';
import {
  MapPin,
  Briefcase,
  Users,
  Language,
  Star,
  Backpack,
  School,
  Flag,
  BuildingFactory2,
  Eye
} from 'tabler-icons-react';
import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import useCURDStore from '../hooks/useCURDStore';
import dayjs from 'dayjs';
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import { isNotEmpty, useForm } from '@mantine/form';

const CriteriaInput = ({ criteria, label, values, setValues, icon }) => {
  const [query, setQuery] = useState('');
  const [data, setData] = useState([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get(criteria)) {
      const value = searchParams.get(criteria).split(',');
      setData(value.map((item) => ({ value: item, label: item })));
      setValues((current) => ({ ...current, [criteria]: value }));
    }
  }, [searchParams]);

  return (
    <MultiSelect
      key={criteria}
      data={data}
      value={values[criteria] || []}
      placeholder={label}
      searchable
      creatable
      icon={icon}
      rightSection={<></>}
      getCreateLabel={(query) => `+ ${query}`}
      searchValue={query}
      onSearchChange={(query) => setQuery(query)}
      onChange={(value) => {
        if (value.length === 0) {
          delete values[criteria];
          setValues({ ...values });
        } else {
          setValues((current) => ({ ...current, [criteria]: value }));
        }
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && query) {
          const item = { value: query, label: query, };
          if (data && data.find((item) => item.value === query)) {
            setQuery('');
            return;
          }
          setData((current) => [...current, item]);
          setValues((current) => ({ ...current, [criteria]: current[criteria] ? [...current[criteria], query] : [query] }));
          setQuery('');
        }
      }}
      onCreate={(query) => {
        const item = { value: query, label: query, };
        if (data && data.find((item) => item.value === query)) return;
        setData((current) => [...current, item]);
        return item;
      }}
      sx={(theme) => ({
        // flex-grow: 1; flex-shrink: 1; flex-basis: 0
        flex: '1 1 0',
      })}
    />
  );
};

const CandidateAdvancedSearch = () => {
  const navigate = useNavigate();
  const [confirmSavedOpen, setConfirmSavedOpen] = useState(false);
  const form = useForm({
    initialValues: {
      search_name: '',
      search_description: '',
    },
    validate: {
      search_name: isNotEmpty('Search Name required'),
      search_description: isNotEmpty('Search Description required')
    }
  });

  const {
    resources: searchResources,
    getResourceParam: getSearchResourceParam,
    flush: flushSearchResource,
  } = useCURDStore({ resourcesName: 'candidate/search' });

  const {
    createResource: createSavedResource,
  } = useCURDStore({ resourcesName: 'candidate/search/saved' });

  const [values, setValues] = useState({});

  const handleSearch = async () => {
    // turn array in object to string 
    let n = {};
    Object.entries(values).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        n[key] = value.join(',');
      }
    });

    await getSearchResourceParam(n);
  };

  const handleSave = async (v) => {
    const param = {
      search_name: v.search_name,
      search_description: v.search_description,
      ...values
    };
    
    const id = await createSavedResource(param);

    if (id) {
      setConfirmSavedOpen(false);
    }
  };


  const Actions = ({ id, f }) => {
    return (
      <Group spacing={4} position="right" noWrap>
        <ActionIcon
          name="summary"
          color="green"
          onClick={() => navigate({
            pathname: `/candidate/${id}`,
            search: createSearchParams({
              tab: 'summary'
            }).toString()
          })}>
          <Eye size={16} />
        </ActionIcon>
      </Group>
    );

  };

  const schema = [
    { name: 'candidate_name', label: 'Candidate Name', sort: true, filter: true },
    { name: 'candidate_email_address', label: 'Candidate Email Address', sort: true, filter: true },
    { name: 'candidate_owner', label: 'Candidate Owner', sort: true, filter: true },
    { name: 'candidate_created_date', label: 'Candidate Created Date', sort: true, filter: true, display: (o) => <>{dayjs(o.from_date).format('DD MMM YYYY')}</> },
    { name: 'actions', label: '', textAlignment: 'right', display: (o, f) => <Actions id={o.uid} f={f} /> },
  ];


  return (
    <Container sx={(theme) => ({
      marginTop: theme.spacing.md,
      maxWidth: '100%'
    })}>
      <Title size={24} sx={(theme) => ({
        color: theme.colors.black[0],
        paddingBottom: theme.spacing.xs,
        '@media (max-width: 768px)': {
          fontSize: '1.5rem',
          paddingTop: theme.spacing.xs,
          alignSelf: 'center',
        }
      })}>
        Candidate Advanced Search
      </Title>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.colors.gray[0],
          padding: theme.spacing.md,
          borderRadius: theme.radius.md,
          paddingBottom: theme.spacing.xl,
        })}
      >
        {/* <Text sx={{ textAlign: 'left', width: '100%' }}>Search Criteria</Text> */}
        <Flex
          direction={{
            base: 'column',
            sm: 'row'
          }}
          justify={'start'}
          gap='xs'
          width='100%'
          sx={(theme) => ({
            flexWrap: 'wrap',
            marginTop: theme.spacing.md,
          })}
        >
          <CriteriaInput criteria='candidate_location' label='Location' values={values} setValues={setValues} icon={<MapPin size={20} />} />
          <CriteriaInput criteria='current_position' label='Experience' values={values} setValues={setValues} icon={<Briefcase size={20} />} />
          <CriteriaInput criteria='current_company' label='Employer' values={values} setValues={setValues} icon={<Users size={20} />} />
          <CriteriaInput criteria='languages' label='Languages' values={values} setValues={setValues} icon={<Language size={20} />} />
          {/* <CriteriaInput criteria='skills' label='Skills' values={values} setValues={setValues} icon={<Star size={20} />} /> */}
          <CriteriaInput criteria='degree' label='Degree' values={values} setValues={setValues} icon={<Backpack size={20} />} />
          <CriteriaInput criteria='university' label='Universities' values={values} setValues={setValues} icon={<School size={20} />} />
          <CriteriaInput criteria='nationality_custom' label='Nationalities' values={values} setValues={setValues} icon={<Flag size={20} />} />
          <CriteriaInput criteria='industry' label='Industries' values={values} setValues={setValues} icon={<BuildingFactory2 size={20} />} />
        </Flex>
        <Flex gap='xs' justify='right'>
          <Button
            sx={(theme) => ({
              marginTop: theme.spacing.md,
              textAlign: 'center',
              width: 100,
              '&:hover': {
                backgroundColor: theme.colors.black[6],
                color: theme.colors.white[0],
              }
            })}
            variant="subtle"
            disabled={Object.values(values).length === 0}
            onClick={() => {
              setValues({});
              flushSearchResource();
            }}
          >
            Clear
          </Button>
          <Button
            sx={(theme) => ({
              marginTop: theme.spacing.md,
              textAlign: 'center',
              width: 100,
            })}
            variant="outline"
            disabled={Object.values(values).length === 0}
            onClick={() => setConfirmSavedOpen(!confirmSavedOpen)}
          >
            Save
          </Button>
          <Button
            sx={(theme) => ({
              marginTop: theme.spacing.md,
              textAlign: 'center',
              width: 100,
            })}
            disabled={Object.values(values).length === 0}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Flex>
      </Box>

      <Box
        sx={{
          width: '100%'
        }}
      >
        {
          searchResources && searchResources.length > 0 && (
            <DataTable
              columns={schema?.map((resource, index) => {
                return {
                  key: index,
                  title: <Text mr={resource.name == 'actions' ? "xs" : "none"}>{resource.label}</Text>,
                  accessor: resource.name,
                  textAlignment: resource.textAlignment || 'left',
                  sortable: false,
                  render: (row) => {
                    return resource.display && resource.display(row) || row[resource.name];
                  }
                };
              })}
              records={searchResources}
            />
          )
        }


      </Box>

      <Modal
        opened={confirmSavedOpen}
        onClose={() => {
          setConfirmSavedOpen(false);
          form.reset();
        }}
        title="Confirm Save"
        centered
      >
        <form form={form} onSubmit={form.onSubmit((values) => {
          handleSave(values);
        })}>
          <SimpleGrid cols={2}>
            <TextInput
              withAsterisk
              label="Search Name"
              placeholder="Search Name..."
              {...form.getInputProps('search_name')}
            />
            <TextInput
              withAsterisk
              label="Search Description"
              placeholder="Search Description"
              {...form.getInputProps('search_description')}
            />
          </SimpleGrid>
          <Flex gap='xs' justify='right'>
            <Button
              sx={(theme) => ({
                marginTop: theme.spacing.md,
                textAlign: 'center',
                width: 100,
                '&:hover': {
                  backgroundColor: theme.colors.black[6],
                  color: theme.colors.white[0],
                }
              })}
              variant="subtle"
              onClick={() => {
                setConfirmSavedOpen(false);
                form.reset();
              }
              }
            >
              Cancel
            </Button>
            <Button
              sx={(theme) => ({
                marginTop: theme.spacing.md,
                textAlign: 'center',
                width: 100,
              })}
              type="submit"
            >
              Save
            </Button>
          </Flex>
        </form>
      </Modal>
    </Container>
  );
};

export default CandidateAdvancedSearch;