import AdminListView from "../../components/adminListView";
import { useNavigate, createSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
import { Menu, Box, ActionIcon, Group, Space, ScrollArea } from "@mantine/core";
import { Dots, Edit, Eye, Search, Trash } from 'tabler-icons-react';
import Header from "../../components/header";



const ClientsList = (props) => {
  const navigate = useNavigate();

  const Actions = ({ id, f }) => {
    return (
      <Group spacing={4} position="right" noWrap>
        <ActionIcon
          name="summary"
          color="green"
          onClick={() => navigate({
            pathname: `/client/${id}`,
            search: createSearchParams({
              tab: 'summary'
            }).toString()
          })}>
          <Eye size={16} />
        </ActionIcon>
        <ActionIcon
          name="delete"
          color="red"
          onClick={async () => { f.deleteResource(id); }}
        >
          <Trash size={16} />
        </ActionIcon>
      </Group>
    );

  };


  const clientSetting = {
    resources: `clients`,
    schema: [
      { name: 'Name', label: 'Client Name', sort: true, filter: true },
      { name: 'JobCount', label: 'Job Count', sort: true, filter: true },
      { name: 'Location', label: 'Location', sort: true, filter: true },
      { name: 'actions', label: '', textAlignment: 'right', display: (o, f) => <Actions id={o.ID} f={f} /> },
    ],
    action: [
    ],
    createAllowed: true,
  };
  return (
		<ScrollArea h={"calc(100vh - 80px)"}>
      <Header>
        Client
      </Header>
      <Space h="md" />
      <AdminListView {...clientSetting} />
    </ScrollArea>
  );
};

export default ClientsList;