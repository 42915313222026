import useCURDStore from '../../hooks/useCURDStore';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import JobDetailView from './jobDetailView';
import { Button, Flex, Paper, ScrollArea } from '@mantine/core';
import { IconAlertCircle, IconFilePlus } from '@tabler/icons';
import { Check } from 'tabler-icons-react';
import { notifications } from '@mantine/notifications';
import api from "../../api";

const JobCreate = () => {

	const navigate = useNavigate();

	const { page,
		totalPage,
		loading,
		resource,
		resources,
		setPage,
		resourcesUpdated,
		listResources,
		getResource,
		createResource,
		updateResource,
		deleteResource, } = useCURDStore({ resourcesName: 'jobs' });
	const [dirty, setDirty] = useState(false);
	const [specialities, setSpecialities] = useState([]);
	const [jobObject, setJobObject] = useState({
		JobReference: null,
		PositionName: null,
		Location: null,
		Address: null,
		Headcount: 0,
		ExperienceLevel: null,
		ExpectedCloseAt: null,
		MinSalary: 0,
		MaxSalary: 0,
		SalaryCurrencyCode: null,
		Frequency: null,
		ContractDetails: null,
		OpenAt: null,
		CloseAt: null,
		ClientId: null,
		Client: {},
		Specialities: [],
		IsRemote: false,
		JobDescription: null,
		PackageDetails: null,
		Remarks: null,
		JobAssignment:'[]'
	})

	useEffect(() => {
		const fs = async () => api().get('/specialities').then((response) => {
			setSpecialities(response.data);
		});

		fs();
	}, []);

	return (<ScrollArea h={"calc(100vh - 80px)"}>
		{dirty &&
			<Flex
				direction="row"
				// justify="space-between"
				justify="center"
				align="center"
				padding="xs"
				width="100%"
				sx={() => ({
					backgroundColor: 'rgba(255, 0, 0, 0.1)',
				})}
			>
				<Paper color="red" sx={() => {
					return ({
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						flexDirection: 'row',
						margin: '1rem 2rem',
						backgroundColor: 'transparent',
					})
				}}>
					<IconAlertCircle size="1.5rem" />&nbsp;Click save job to save</Paper>
				<Button onClick={async () => {
					const id = await createResource({
						...jobObject,
					});
					navigate(`/job/${id}`);
					notifications.show({
						icon: <Check />,
						message: 'Created job successfully',
					})
				}}><IconFilePlus /> &nbsp;Save Job</Button>
			</Flex>
		}
		<JobDetailView {...{
			create: true,
			specialities,
			jobObject,
			resourcesUpdated,
			updateResource: (_, job) => {
				setDirty(true);
				setJobObject({
					...jobObject,
					...job.data,
				});
			},
		}} />)
	</ScrollArea>
	)
};

export default JobCreate;