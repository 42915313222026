import { UnstyledButton, Menu, Text, ActionIcon, ActionIText, AspectRatio, Image, Box, Checkbox, Space, Title, Input, MediaQuery, MultiSelect, Button, Textarea, Select, Container, Grid, Tabs, Divider, SimpleGrid, Flex, Modal } from "@mantine/core";
import { Photo } from 'tabler-icons-react';
import { DatePicker } from '@mantine/dates';
import { Dropzone } from "@mantine/dropzone";
import { useEffect } from "react";
import { Edit } from 'tabler-icons-react';
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
import { IconSettings, IconDotsVertical, IconEye, IconTrash, IconUsers, IconPhoto, IconUserCheck } from '@tabler/icons';
import KanbanBoard from "../../components/kanbanBoard/kanbanBoard";
import { useState } from "react";
// import axios from "../../api";
import api from "../../api";
import JobAssignmentTable from "./detailsTabs/jobAssignmentTable";
import EditableText from "../../components/editableText";
import TextEditor from "../../components/richTextEditor";
import EditableMultiSelect from "../../components/editableMultiSelect";
import EditableSelect from "../../components/editableSelect";
import ShareButton from "../../components/share";
import { useClientStoreContext, useJobStoreContext } from "../../stores/useStoreContext";
import { useFieldStoreContext } from "../../stores/useStoreContext";


const CandidateRemarkPanel = ({ id, candidates, onClose }) => {
  const [remark, setRemark] = useState('');
  const [candidate, setCandidate] = useState(null);

  useEffect(() => {
    if (!id) return;
    const c = candidates.find((c) => c.id === id);
    setCandidate(c);
    setRemark(c.remarks);
  }, [id, candidates]);

  const handleSave = async () => {
    await api().post(`/candidates/${id}`, {
      data: { Remarks: remark },
    });
    onClose();
  }

  return (
    <Modal opened={id} onClose={() => onClose()} size="xl" title={`Set remark for ${candidate?.name || ''}`}>
      <Textarea
        multiline
        minRows={15}
        value={remark}
        onChange={(e) => setRemark(e.target.value)}
      />

      <Button onClick={handleSave}>Save and close</Button>
    </Modal>
  )
}

const JobDetailView = ({
  create,
  jobObject,
  specialities,
  resourcesUpdated,
  updateResource
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get('tab') || 'summary';
  const { findClients } = useClientStoreContext();
  const { jobCandidates,
    getCandidatesByJobID,
    setCandidatesStatus,
    removeCandidateFromJob } = useJobStoreContext();
    const { job_extra_field } = useFieldStoreContext()

  const [clients, setClients] = useState([{
    label: 'Loading...',
    value: null,
  }]);

  const [users, setUsers] = useState([]);

  const [job, setJob] = useState(jobObject);
  const [remarkPanelOpen, setRemarkPanelOpen] = useState(null);

  const [laneCandidates, setLaneCandidates] = useState({
    lanes: [
      {
        id: 'SELECTED',
        title: <Text>Selected</Text>,
        cards: [],
      },
      {
        id: 'CONSULTANT_INTERVIEW',
        title: <Text>Consultant Interview</Text>,
        cards: []
      },
      {
        id: 'PRESENTED',
        title: <Text>Presented</Text>,
        cards: []
      },
      {
        id: 'CLIENT_INTERVIEWS',
        title: <Text>Client Interviews</Text>,
        cards: []
      },
      {
        id: '2ND_INTERVIEWS',
        title: <Text>2nd Interviews</Text>,
        cards: []
      },
      {
        id: '3RD_INTERVIEWS',
        title: <Text>3rd Interviews</Text>,
        cards: []
      },
      {
        id: 'OFFERED',
        title: <Text>Offered</Text>,
        cards: []
      },
      {
        id: 'HIRED',
        title: <Text>Hired</Text>,
        cards: []
      },
      {
        id: 'STARTED',
        title: <Text>Started</Text>,
        cards: []
      },
      {
        id: 'PROBATION_PASSED',
        title: <Text>Probation Passed</Text>,
        cards: []
      },
    ]
  });

  useEffect(() => {
    setJob(jobObject);
  }, [jobObject])

  async function handleEdit(variable, data) {
    if (data == job[variable]) return;
    if (variable === 'Specialities') {
      data = data.map((v) => ({ 'ID': v }));
    }
    setJob({ ...job, [variable]: data });
    updateResource(job.ID, { data: { [variable]: data } });
  }

  function showPanel(candidateID) {
    return () => {
      setRemarkPanelOpen(candidateID);
    }
  }

  useEffect(() => {
    const fetchUsers = async () => {
      const { data } = await api().get('/users');
      setUsers(data.data);
    }
    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      const { data } = await findClients();
      setClients(data.map(c => ({
        label: c.Name,
        value: c.ID,
      })));

      // now check if querystring have client id
      const clientID = searchParams.get('clientid');
      if (clientID) {
        handleEdit('ClientId', clientID);
      }
    }
    getCandidatesByJobID(job.ID);
    fetchClients();
  }, []);

  useEffect(() => {
    if (jobCandidates) {
      setLaneCandidates((prev) => {
        const newLanes = prev.lanes.map(lane => {
          const newCards = jobCandidates?.filter(candidate => candidate.Status === lane.id).map(c => {
            return {
              id: c.id,
              title: <Text>{c.name}</Text>,
              description: <Flex justify='space-between' direction={"column"}>
                <Flex>
                  <Text>{c.currentPosition}</Text>
                  <ActionIcon
                    name="summary"
                    color="green"
                    onClick={() => navigate({
                      pathname: `/candidate/${c.id}`,
                      search: createSearchParams({
                        tab: 'summary'
                      }).toString(),
                      target: '_blank'
                    })}>
                    <IconEye size={16} />
                  </ActionIcon>
                </Flex>
                <Flex>
                  <Button onClick={showPanel(c.id)}>{c.remarks === '' ? "Add Remark" : "Edit Remark"}</Button>
                </Flex>
              </Flex>,
              label: <Flex justify='flex-end' mr="-10px">
                <Menu shadow="md" sx={{ textAlign: "right" }} trigger="click">
                  <Menu.Target>
                    <ActionIcon
                      name="menu"
                      variant='transparent'
                    >
                      <IconDotsVertical size={16} />
                    </ActionIcon>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item
                      icon={<IconTrash size={14} />}
                      sx={{ textAlign: "right" }}
                      onClick={() => removeCandidateFromJob(job.ID, c.id)}
                    >
                      Remove
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </Flex>,
              draggable: true,
            };
          });
          return {
            ...lane,
            cards: newCards,
          };
        });
        return {
          ...prev,
          lanes: newLanes,
        };
      });

    } else {
      // clean up the lanes
      setLaneCandidates((prev) => {
        const newLanes = prev.lanes.map(lane => {
          return {
            ...lane,
            cards: [],
          };
        });
        return {
          ...prev,
          lanes: newLanes,
        };
      });
    }
  }, [job, jobCandidates, navigate, removeCandidateFromJob]);

  return (
    <Container sx={(theme) => ({
      marginTop: theme.spacing.md,
      maxWidth: '100%',
    })}>
      <CandidateRemarkPanel id={remarkPanelOpen} candidates={jobCandidates} onClose={() => setRemarkPanelOpen(null)} />
      <Grid sx={(theme) => ({ borderRadius: 3, backgroundColor: theme.colors.brand[0], padding: theme.spacing.md })}>
        <Grid.Col span={10}>
          <Title order={1} sx={(theme) => ({
            color: theme.colors.black[0],
            paddingBottom: theme.spacing.xs,
            '@media (max-width: 768px)': {
              fontSize: '1.5rem',
              paddingTop: theme.spacing.xs,
            }
          })}>
            {job?.PositionName ? job.PositionName : 'New Job'}
            {/* <br />{!create && <Button>Add Candidate</Button>} */}
          </Title>
          <ShareButton />
        </Grid.Col>
        <Grid.Col span={2}>
          <Menu shadow="md" width={200} sx={{ textAlign: "right" }} trigger="click">
            <Menu.Target>
              <Title>
                <UnstyledButton

                  sx={(theme) => ({
                    width: '26px',
                    '@media (max-width: 768px)': {
                      width: '1rem',
                    }
                  })}
                >
                </UnstyledButton>
              </Title>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item icon={<IconSettings size={14} />}
                onClick={() => navigate(``)}
                sx={{ textAlign: "right" }}
              >
                Edit
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Grid.Col>
        <Grid.Col>
          <Tabs defaultValue={tabParam} value={tabParam} onTabChange={t => { setSearchParams({ 'tab': t }); }}>
            <Tabs.List>
              {!create && <Tabs.Tab value="candidates" icon={<IconUsers size={14} />}>Candidates</Tabs.Tab>}
              <Tabs.Tab value="summary" icon={<IconPhoto size={14} />}>Summary</Tabs.Tab>
              {!create && <Tabs.Tab value="job_assignment_table" icon={<IconUserCheck size={14} />}>Job Assignment</Tabs.Tab>}
            </Tabs.List>

            <Tabs.Panel value="candidates" pt="xs">
              {
                laneCandidates ?
                  <KanbanBoard
                    data={laneCandidates}
                    onDataChange={(n) => {
                    }}
                    onCardMoveAcrossLanes={async (fromLaneId, toLaneId, cardId, index) => {
                      setCandidatesStatus(job.ID, cardId, toLaneId);
                    }
                    }
                  /> :
                  <Text>No candidates</Text>
              }
            </Tabs.Panel>
            <Tabs.Panel value="summary" pt="xs">
              <Grid>
                <Grid.Col md={6} sm={12} sx={(theme) => ({
                  [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                    paddingBottom: 0,
                    marginBottom: 0,
                  }
                })}>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: theme.colors.brand[0],
                      padding: theme.spacing.sm,
                      margin: theme.spacing.sm,
                      borderRadius: theme.radius.md,
                      [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                        marginBottom: 0,
                      }
                    })}
                  >
                    <Title order={4} sx={(theme) => ({ paddingBottom: theme.spacing.sm })}>
                      Job Description
                    </Title>
                    <Divider sx={(theme) => ({ paddingBottom: theme.spacing.sm })} />
                    <TextEditor
                      content={job?.JobDescription}
                      onEdit={async (v) => {
                        await handleEdit('JobDescription', v);
                      }}
                    />

                  </Box>
                </Grid.Col>
                <Grid.Col md={6} sm={12} sx={(theme) => ({
                  [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                    paddingTop: 0,
                    marginTop: 0,
                  }
                })}>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: theme.colors.brand[0],
                      padding: theme.spacing.sm,
                      margin: theme.spacing.sm,
                      borderRadius: theme.radius.md,
                    })}
                  >
                    <Title order={4} sx={(theme) => ({ paddingBottom: theme.spacing.sm })}>
                      About this Job Offering
                    </Title>
                    <Divider sx={(theme) => ({ paddingBottom: theme.spacing.sm })} />
                    {/* <EditableText
                      label='Owner Name'
                      value={aboutThisJob?.jobOwner.name}
                    />
										<EditableText
                      label='Owner contact'
                      value={aboutThisJob?.jobOwner.email}
                    /> */}
                    {/* <EditableText
                      label='Commission'
                      value={aboutThisJob?.commission + "%"}
                    /> */}
                    <EditableSelect
                      key={clients.length}
                      onEdit={async (v) => {
                        await handleEdit('ClientId', v);
                      }}
                      label='Client'
                      value={job?.ClientId}
                      data={clients}
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('JobReference', v);
                      }}
                      label='Job Reference'
                      value={job?.JobReference}
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('OpenAt', v);
                      }}
                      isDate
                      label='Open At'
                      value={job?.OpenAt}
                    />
                    <EditableText
                      isDate
                      onEdit={async (v) => {
                        await handleEdit('ExpectedCloseAt', v);
                      }}
                      label='Expected Close At'
                      value={job?.ExpectedCloseAt}
                    />
                    <EditableSelect
                      key={users.length}
                      label='Owner'
                      value={job?.Owner?.id || ''}
                      data={users.length ? users.map((u) => ({ label: u.name, value: u.id })) : []}
                      onEdit={async (v) => {
                        await handleEdit('OwnerId', v);
                      }}
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('CloseAt', v);
                      }}
                      isDate
                      label='Close At'
                      value={job?.CloseAt}
                    />
                    <EditableText
                      readonly
                      isDate
                      label='Created At'
                      value={job?.CreatedAt}
                    />
                    <EditableText
                      readonly
                      isDate
                      label='Updated At'
                      value={job?.UpdatedAt}
                    />
                  </Box>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: theme.colors.brand[0],
                      padding: theme.spacing.sm,
                      margin: theme.spacing.sm,
                      borderRadius: theme.radius.md,
                    })}
                  >
                    <Title order={4} sx={(theme) => ({ paddingBottom: theme.spacing.sm })}>
                      Job Details
                    </Title>
                    <Divider sx={(theme) => ({ paddingBottom: theme.spacing.sm })} />
                    <EditableSelect
                      onEdit={async (v) => {
                        await handleEdit('IsRemote', v);
                      }}
                      label='Remote?'
                      value={job?.IsRemote}
                      data={[
                        { label: 'Yes', value: true },
                        { label: 'No', value: false },
                      ]}
                    />
                    <EditableSelect
                      onEdit={async (v) => {
                        await handleEdit('ExperienceLevel', v);
                      }}
                      label='Experience Level'
                      value={job?.ExperienceLevel}
                      data={[
                        { label: 'Junior', value: 'junior' },
                        { label: 'Middle', value: 'middle' },
                        { label: 'Senior', value: 'senior' },
                      ]}
                    />
                    <EditableSelect
                      onEdit={async (v) => {
                        await handleEdit('ContractDetails', v);
                      }}
                      label='Contract Details'
                      value={job?.ContractDetails}
                      data={[
                        { label: 'Full time', value: 'full_time' },
                        { label: 'Part time', value: 'part_time' },
                        { label: 'Temporary', value: 'temporary' },
                        { label: 'Freelance', value: 'freelance' },
                        { label: 'Internship', value: 'internship' },
                        { label: 'Apprenticeship', value: 'apprenticeship' },
                        { label: 'Contractor', value: 'contractor' },
                        { label: 'Consultancy', value: 'consultancy' },
                      ]}
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('PositionName', v);
                      }}
                      label='Position Name'
                      value={job?.PositionName}
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('Location', v);
                      }}
                      label='Working Location'
                      value={job?.Location}
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('Address', v);
                      }}
                      label='Office Address'
                      value={job?.Address}
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('Headcount', parseInt(v));
                      }}
                      label='Headcount'
                      value={job?.Headcount}
                    />
                    <EditableMultiSelect
                      onEdit={async (v) => {
                        await handleEdit('Specialities', v);
                      }}
                      label='Specialities'
                      data={specialities?.map(s => ({ label: s.industry + ' - ' + s.speciality, value: s.ID })) || []}
                      value={job.Specialities?.map(s => s.ID) || []}
                    />
                  </Box>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: theme.colors.brand[0],
                      padding: theme.spacing.sm,
                      margin: theme.spacing.sm,
                      borderRadius: theme.radius.md,
                    })}
                  >
                    <Title order={4} sx={(theme) => ({ paddingBottom: theme.spacing.sm })}>
                      Package Details
                    </Title>
                    <Divider sx={(theme) => ({ paddingBottom: theme.spacing.sm })} />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('MinSalary', Number(v));
                      }}
                      label='Min Salary'
                      value={job?.MinSalary}
                      currency
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('MaxSalary', Number(v));
                      }}
                      label='Max Salary'
                      value={job?.MaxSalary}
                      currency
                    />
                    <EditableText
                      onEdit={async (v) => {
                        await handleEdit('Frequency', v);
                      }}
                      label='Frequency'
                      value={job?.Frequency}
                    />
                    <EditableSelect
                      onEdit={async (v) => {
                        await handleEdit('SalaryCurrencyCode', v);
                      }}
                      label='Currency'
                      value={job?.SalaryCurrencyCode}
                      data={[
                        { label: 'HKD', value: 'HKD' },
                        { label: 'CNY', value: 'CNY' },
                        { label: 'USD', value: 'USD' },
                        { label: 'EUR', value: 'EUR' },
                        { label: 'JPY', value: 'JPY' },
                        { label: 'GBP', value: 'GBP' },
                        { label: 'AUD', value: 'AUD' },
                        { label: 'CAD', value: 'CAD' },
                        { label: 'SGD', value: 'SGD' },
                        { label: 'MYR', value: 'MYR' },
                      ]}
                    />
                    <TextEditor
                      content={job?.PackageDetails}
                      onEdit={async (v) => {
                        await handleEdit('PackageDetails', v);
                      }}
                    />
                  </Box>
                  <Box
                    sx={(theme) => ({
                      backgroundColor: theme.colors.brand[0],
                      padding: theme.spacing.sm,
                      margin: theme.spacing.sm,
                      borderRadius: theme.radius.md,
                    })}
                  >
                    <Title order={4} sx={(theme) => ({ paddingBottom: theme.spacing.sm })}>
                      Additional Information
                    </Title>
                    {job_extra_field.map((item) => (
													<EditableText
														key={item.id}
														onEdit={async (v) => {
															await handleEdit(item.label, v);
														}}
														label={item.label}
														value={job[item.label]||''}
													/>
												))}
                  </Box>
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
            <Tabs.Panel value="job_assignment_table" pt="xs">
              <JobAssignmentTable job={job} />
            </Tabs.Panel>
          </Tabs>
        </Grid.Col>
      </Grid>
    </Container>
  );
};
export default JobDetailView; 