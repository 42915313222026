import AdminListView from "../../components/adminListView";
import { useNavigate, createSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
import { Modal, TextInput, Box, ActionIcon, Group, Space, NavLink, Flex, Button } from "@mantine/core";
import { UserMinus, ArrowsVertical, BrowserOff, BrowserPlus, Dots, Edit, Eye, Minimize, Search, Trash, UserPlus, Check, Businessplan, ShoppingCartPlus, UserCheck } from 'tabler-icons-react';
import Header from "../../components/header";
import Query from "../../components/Query";
import { IconChevronsLeft, IconChevronRight, IconSearch, IconChevronsRight } from "@tabler/icons";
import React from "react";
import CandidateTable from "../../components/candidateTable";
import CandidateDetailsView from "./candidateDetailView";
import { useState } from "react";
import axios from "../../api";
import api from "../../api";
import useCURDStore from "../../hooks/useCURDStore";
import { notifications } from "@mantine/notifications";
import AddCandidatesToJobModal from "../job/addToJobModel";
import { useCandidateSearchStoreContext } from "../../stores/useStoreContext";

const SearchBar = ({ search, setActive, active, retract, setRetract }) => {
	return (
		<div className='searchBar' style={{ width: '160px', transform: `translateX(${retract?-130:0}px)`,transition:'all 0.2s linear 0s', }}>
			<NavLink
				label="Search"
				icon={<IconSearch size="1rem" stroke={1.5} />}
				rightSection={retract ? <IconChevronsRight onClick={() => setRetract(!retract)} size="1rem" stroke={1.5} /> : <IconChevronsLeft onClick={() => setRetract(!retract)} size="1rem" stroke={1.5} />}
				variant="subtle"
			/>
			{search.map(s =>
				<NavLink
					key={s.id}
					label={s.name}
					color="blue"
					rightSection={<IconChevronRight size="1rem" stroke={1.5} />}
					variant="subtle"
					onClick={() => setActive(s.id)}
					active={active === s.id}
				/>
			)}
		</div>
	)
}

const CandidatesList = (props) => {
	const navigate = useNavigate();
	console.log(window.innerWidth)
	const [retract, setRetract] = useState(window.innerWidth < 720)
	const {
		search,
		sortBy,
		searchState,
		limit,
		activeSearchId,
		findAllSearch,
		setPage,
		setLimit,
		setSort,
		onQuerySubmit,
		getCandidates,
		setActiveSearchWithId,
		refreshCurrentSearch,
	} = useCandidateSearchStoreContext();

	const [isModalOpen, setModalOpen] = useState(false);
	const [refreshKey, setRefreshKey] = useState(0);

	const [isPreviewLoading, setIsPreviewLoading] = useState(true);
	const [addJobModalVisible, setAddJobModalVisible] = useState(false);

	const [activeCandidate, _setActiveCandidate] = useState(null);
	const [previewState, setPreview] = useState(null);
	const [functions, setFunctions] = useState([]);
	const [specialities, setSpecialities] = useState([]);
	const {
		updateResource: _updateResource,
	} = useCURDStore({ resourcesName: 'candidates' });
	const [candidateSelections, setCandidateSelections] = useState([]);

	const _onQuerySubmit = async (query, sql, iQuery) => {
		// await searchState[activeSearchId].setSql(sql);
		// await searchState[activeSearchId].setIQuery(iQuery);
		// await searchState[activeSearchId].setPage(1);
		// await searchState[activeSearchId].setTotal(0);
		// await searchState[activeSearchId].setData([]);
		// await refreshCurrentSearch();
		// cancel all selected candidates
		setCandidateSelections([]);
		await onQuerySubmit(query, sql, iQuery);
	}

	const selectAll = async () => {
		// select all the candidates in the current page
		const ids = searchState[activeSearchId].data.map(c => c.ID);
		setCandidateSelections(ids);
	}

	const deselectAll = async () => {
		// deselect all the candidates in the current page
		setCandidateSelections([]);
	}

	const updateResource = async (id, resource) => {
		await _updateResource(id, resource);
		await setActiveCandidate(id);
		notifications.show({
			icon: <Check />,
			message: 'Updated candidate successfully',
		})
	}

	const setActiveCandidate = async (candidateId) => {
		setIsPreviewLoading(true);
		if (!previewState) {
			setPreview(7);
		}
		const { data } = await axios().get(`/candidates/${candidateId}`);
		_setActiveCandidate(data);
		setIsPreviewLoading(false);
	}

	const saveQuery = async () => {
		// Open a modal or use a simple prompt to ask query name
		const queryName = window.prompt('Please enter a name for your query');

		if (!queryName) {
			return;
		}

		// Save the query to the backend
		await api().post('/searches/new', {
			Name: queryName,
			Sql: searchState[activeSearchId].sql,
			IQuery: JSON.stringify(searchState[activeSearchId].iQuery),
		});

		// Refresh the list of searches
		findAllSearch();
	}

	const removeCandidate = async () => {
		const q = await api().delete(`/candidates?ids=${candidateSelections.join(',')}`);
		if (q.status === 200) {
			notifications.show({
				icon: <Check />,
				message: 'Removed candidates successfully',
			})
			_setActiveCandidate(null);
			setCandidateSelections([]);
			refreshCurrentSearch()
		}
	}

	const removeQuery = async () => {
		await api().delete(`/searches/${searchState[activeSearchId].id}`);
		findAllSearch();
	}

	const modifyQuery = async () => {
		const queryName = window.prompt('Please enter a name for your query', searchState[activeSearchId].name);

		if (!queryName) {
			return;
		}

		// Save the query to the backend
		await api().post(`/searches/${searchState[activeSearchId].id}`, {
			Name: queryName,
			Sql: searchState[activeSearchId].sql,
			IQuery: JSON.stringify(searchState[activeSearchId].iQuery),
		});

		// Refresh the list of searches
		findAllSearch();
	}

	React.useEffect(() => {
		const ff = async () => api().get('/functions').then((response) => {
			setFunctions(response.data);
		});
		const fs = async () => api().get('/specialities').then((response) => {
			setSpecialities(response.data);
		});

		findAllSearch();
		ff();
		fs();
		getCandidates();
	}, [])

	return (
		<Box
			sx={() => ({
				display: "flex",
				flexDirection: "column",
				height: "calc(100vh - 80px)",
			})}
		>
			<Box
				sx={() => ({
					display: "flex",
				})}
			>
				<Box
					sx={() => ({
						paddingTop: "1rem",
						width: retract ?window.innerWidth>720?40: 25 : 160 + 'px',
						transition:'all 0.2s linear 0s',
						overflowX: "hidden",
						height:
							previewState === null
								? "calc(100vh - 80px - 1rem)"
								: previewState === 7
									? "calc(30vh - 80px - 1rem)"
									: previewState === 5
										? "calc(50vh - 80px - 1rem)"
										: previewState === 3
											? "calc(70vh - 80px - 1rem)"
											: null,
						borderRight: "1px solid #ebebeb",
						overflowY: "scroll",
					})}
				>
					<SearchBar
						retract={retract}
						setRetract={setRetract}
						search={search}
						active={activeSearchId}
						setActive={setActiveSearchWithId}
					/>
				</Box>
				<Box
					sx={() => ({
						flex: 1,
						height:
							previewState === null
								? "calc(100vh - 80px)"
								: previewState === 7
									? "calc(30vh - 80px)"
									: previewState === 5
										? "calc(50vh - 80px)"
										: previewState === 3
											? "calc(70vh - 80px)"
											: null,
						overflowY: "scroll",
					})}
				>
					<Query
						onSubmit={_onQuerySubmit}
						initalIQuery={searchState[activeSearchId].iQuery}
						saveQuery={saveQuery}
						removeQuery={removeQuery}
						modifyQuery={modifyQuery}
						isNewQuery={searchState[activeSearchId].name === "New Search"}
					/>
					<Button
						onClick={
							// select all the candidates in the current page if not all are selected
							() => {
								if (
									candidateSelections?.length !==
									searchState[activeSearchId]?.data?.length
								) {
									selectAll();
								} else {
									deselectAll();
								}
							}
						}
					>
						<UserCheck size={20} />
						&nbsp;{(candidateSelections?.length !==
							searchState[activeSearchId]?.data?.length) && "Select All" || "Deselect All"}
					</Button>
					<Button onClick={() => navigate(`/candidate/create`)}>
						<UserPlus size={20} />
						&nbsp;New Candidate
					</Button>
					<Button
						disabled={candidateSelections.length === 0}
						onClick={() => {
							// prompt user to confirm
							// js confirm dialog
							if (
								window.confirm(
									"Are you sure you want to remove the selected candidates?"
								)
							) {
								// remove the selected candidates
								removeCandidate();
							}
						}}
					>
						<UserMinus size={20} />
						&nbsp;Remove Candidate
					</Button>
					<Button
						disabled={candidateSelections.length === 0}
						onClick={() => setAddJobModalVisible(true)}
					>
						<ShoppingCartPlus size={20} />
						&nbsp;Add to job
					</Button>
					<AddCandidatesToJobModal
						visible={addJobModalVisible}
						cancel={() => setAddJobModalVisible(false)}
						candidates={candidateSelections}
					/>
					<CandidateTable
						key={refreshKey}
						data={searchState[activeSearchId].data || []}
						onCandidateClick={setActiveCandidate}
						sortBy={sortBy}
						setSortBy={setSort}
						activePage={searchState[activeSearchId].page}
						limit={limit}
						setLimit={setLimit}
						totalItems={searchState[activeSearchId].total}
						setPage={setPage}
						selections={candidateSelections}
						setSelections={setCandidateSelections}
					/>
				</Box>
			</Box>
			<Box
				sx={() => ({
					flex: 1,
					overflowY: "scroll",
					borderTop: "1px solid #ebebeb",
					position: "relative",
				})}
			>
				<Flex
					flex={1}
					direction="row"
					align="center"
					justify="center"
					sx={() => ({
						fontFamily: "Arial, Helvetica, sans-serif",
						fontSize: ".7rem",
						fontWeight: "bold",
						fontStretch: "normal",
						textTransform: "uppercase",
						letterSpacing: "5px",
						position: "fixed",
						width: "calc( 100% - 160px )",
						backgroundColor: "#f5f5f5",
						color: "#4a4a4a",
						zIndex: 1,
						borderBottom: "1px solid #ebebeb",
					})}
				>
					<Flex
						align="center"
						sx={() => ({
							padding: "3px 1rem",
							borderRight: "1px solid #ebebeb",
							borderLeft: "1px solid #ebebeb",
							cursor: "pointer",
							userSelect: "none",
						})}
						onClick={() => setPreview(null)}
					>
						<Minimize size={13} />
						&nbsp; MIN
					</Flex>
					<Flex
						align="center"
						sx={() => ({
							padding: "3px 1rem",
							borderRight: "1px solid #ebebeb",
							cursor: "pointer",
							userSelect: "none",
						})}
						onClick={() => setPreview(3)}
					>
						<ArrowsVertical size={13} />
						&nbsp;30%
					</Flex>
					<Flex
						align="center"
						sx={() => ({
							padding: "3px 1rem",
							borderRight: "1px solid #ebebeb",
							cursor: "pointer",
							userSelect: "none",
						})}
						onClick={() => setPreview(5)}
					>
						<ArrowsVertical size={13} />
						&nbsp;50%
					</Flex>
					<Flex
						align="center"
						sx={() => ({
							padding: "3px 1rem",
							borderRight: "1px solid #ebebeb",
							cursor: "pointer",
							userSelect: "none",
						})}
						onClick={() => setPreview(7)}
					>
						<ArrowsVertical size={13} />
						&nbsp;70%
					</Flex>
					<Flex
						align="center"
						sx={() => ({
							padding: "3px 1rem",
							borderRight: "1px solid #ebebeb",
							cursor: "pointer",
							userSelect: "none",
						})}
						onClick={() =>
							window.open(`/candidate/${activeCandidate.ID}`, "_blank")
						}
					>
						<BrowserPlus size={13} />
						&nbsp;New Window
					</Flex>
				</Flex>
				{activeCandidate && (
					<CandidateDetailsView
						isLoading={isPreviewLoading}
						candidateObject={activeCandidate}
						functions={functions}
						specialities={specialities}
						updateResource={updateResource}
					/>
				)}
			</Box>
		</Box>
	);
};

export default CandidatesList;