import React, { useEffect, useState } from "react";
import { Table, Group, ActionIcon } from '@mantine/core';
import { Dots, Edit, Eye, Search, Trash } from 'tabler-icons-react';

const elements = [
    { position: 6, mass: 12.011, symbol: 'C', name: 'Carbon' },
    { position: 7, mass: 14.007, symbol: 'N', name: 'Nitrogen' },
    { position: 39, mass: 88.906, symbol: 'Y', name: 'Yttrium' },
    { position: 56, mass: 137.33, symbol: 'Ba', name: 'Barium' },
    { position: 58, mass: 140.12, symbol: 'Ce', name: 'Cerium' },
];
const FieldItem = (props) => {
    const { data, editField, deleteFun } = props

    const rows = data.map((element) => (
        <tr key={element.id}>
            <td>{element.label}</td>
            <td>{element.type}</td>
            <td>
                <Group spacing={4} position="left" noWrap>
                    <ActionIcon
                        name="summary"
                        color="green"
                        onClick={() => editField(element, 'Candidate')}>
                        <Eye size={16} />
                    </ActionIcon>
                    <ActionIcon
                        name="delete"
                        color="red"
                        onClick={async () => { deleteFun(element.id) }}
                    >
                        <Trash size={16} />
                    </ActionIcon>
                </Group>
            </td>
        </tr>
    ));

    return (
        <>
            <Table>
                <thead>
                    <tr>
                        <th>name</th>
                        <th>type</th>
                        <th>Active</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        </>
    )
}
export default FieldItem