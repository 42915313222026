import useCURDStore from '../../hooks/useCURDStore';
import { useEffect, useState } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import CandidateDetailsView from './candidateDetailView';
import { Button, Flex, Box, Alert, Paper, ScrollArea } from "@mantine/core";
import { IconAlertCircle, IconUserPlus } from '@tabler/icons-react';
import api from "../../api";
import { notifications } from '@mantine/notifications';
import { Check } from 'tabler-icons-react';

const CandidateCreate = () => {

	const navigate = useNavigate();
	const { 
		createResource,
	} = useCURDStore({ resourcesName: 'candidates' });

	const [candidateObject, setCandidateObject] = useState({
		Specialities: [],
		Functions: [],
		Skills: [],
		Tag: [],
		Assessment: '[]',
	})
	const [dirty, setDirty] = useState(false);
	const [functions, setFunctions] = useState([]);
	const [specialities, setSpecialities] = useState([]);

	useEffect(() => {
		const ff = async () => api().get('/functions').then((response) => {
			setFunctions(response.data);
		});
		const fs = async () => api().get('/specialities').then((response) => {
			setSpecialities(response.data);
		});
		ff();
		fs();
	}, []);

	return (
		<ScrollArea h={"calc(100vh - 80px)"}>
			{dirty && (
				<Flex
					direction="row"
					// justify="space-between"
					justify="center"
					align="center"
					padding="xs"
					width="100%"
					sx={() => ({
						backgroundColor: "rgba(255, 0, 0, 0.1)",
					})}
				>
					<Paper
						color="red"
						sx={() => {
							return {
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flexDirection: "row",
								margin: "1rem 2rem",
								backgroundColor: "transparent",
							};
						}}
					>
						<IconAlertCircle size="1.5rem" />
						&nbsp;Click save candidate to save
					</Paper>
					<Button
						onClick={async () => {
							console.log("candidate", candidateObject);
							const id = await createResource({
								...candidateObject,
							});
							navigate(`/candidate/${id}`);
							notifications.show({
								icon: <Check />,
								message: "Created candidate successfully",
							});
						}}
					>
						<IconUserPlus /> &nbsp;Save Candidate
					</Button>
				</Flex>
			)}
			<CandidateDetailsView
				{...{
					functions,
					specialities,
					candidateObject,
					createCandidate: true,
					updateResource: (_, candidate) => {
						console.log("update");
						console.log(candidate);
						setDirty(true);
						// each updateResource is a patch to the candidateObjectState
						// so we need to merge the candidateObjectState with the candidate
						// and then update the candidateObjectState
						setCandidateObject({
							...candidateObject,
							...candidate.data,
						});
						// navigate(`/candidate/${candidate.ID}`);
					},
				}}
			/>
		</ScrollArea>
	);

};

export default CandidateCreate;